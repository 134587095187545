import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputBase, Modal, Stack, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import CustomModal from '../../../../components/CustomModal';

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ResetPasswordDialog = ({ openResetPasswordDialog, setOpenResetPasswordDialog, setOpenLoginSignup }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [newPassword, setNewPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    if (newPassword.trim().length < 6) {
      enqueueSnackbar(translate('password_atleast_6_characters'), snackbarOptionsError);
      return;
    }
    setIsLoading(true);
    conObj
      .post(`password_reset/${localStorage.getItem('resetToken')}.json`, { 'user-new_password': newPassword.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            localStorage.removeItem('resetToken');
            enqueueSnackbar(translate('password_reset_success'), snackbarOptionsSuccess);
            setOpenLoginSignup({ open: true, isLogin: true });
          }
        }
      })
      .catch((err) => {
        localStorage.removeItem('resetToken');
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenResetPasswordDialog(false);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <CustomModal
      open={openResetPasswordDialog}
      onClose={() => setOpenResetPasswordDialog(false)}
      title={translate('reset_password')}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ borderRadius: '10px', border: '1px solid #202a39', height: '44px', p: '8.5px 13px' }}
          >
            <Iconify icon={'ph:lock-light'} width={24} height={24} sx={{ color: '#55657e' }} />
            <InputBase
              type="password"
              name="newPassword"
              value={newPassword}
              placeholder={translate('new_password')}
              sx={{
                ml: 1,
                fontSize: '14px',
                fontWeight: 600,
                fontFamily: 'sans-serif',
                flexGrow: 1,
                color: 'black',
                '& input::placeholder': { color: 'grey.500' },
              }}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Stack>
        </Box>

        <LoadingButton
          sx={{
            width: '100%',
            backgroundColor: 'button.bg',
            color: '#fff',
            '&:focus,:hover': { backgroundColor: 'button.bg' },
          }}
          onClick={() => onSubmit()}
          loading={isLoading}
        >
          {translate('confirm')}
        </LoadingButton>
      </Box>
    </CustomModal>
  );
};

ResetPasswordDialog.propTypes = {
  openResetPasswordDialog: PropTypes.bool,
  setOpenResetPasswordDialog: PropTypes.func,
  setOpenLoginSignup: PropTypes.func,
};

export default ResetPasswordDialog;
