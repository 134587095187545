import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from '../redux/store';
import { setActiveTab } from '../redux/slices/game';

const useGameNav = () => {
  const { activeTab } = useSelector((x) => x.game);

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const changeTab = (slug) => {
    dispatch(setActiveTab(slug));
    if (location.pathname.indexOf('/home') === -1) navigate('/home');
  };

  return {
    activeTab,
    changeTab,
  };
};

export default useGameNav;
