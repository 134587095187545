import { useEffect, useState } from 'react';
import { Box, Dialog, Divider, Grid, IconButton, Stack, Typography, styled } from '@mui/material';
import TelegramLoginButton from 'react-telegram-login';
import { useSnackbar } from 'notistack';
import { LoginSocialFacebook } from 'reactjs-social-login';
import useLocales from '../../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';

import Login from './Login';
import CustomModal from '../../../../components/CustomModal';
import Image from '../../../../components/Image';
import conObj from '../../../../utils/connection-assistant';
import useAuth from '../../../../hooks/useAuth';

import palette from '../../../../theme/palette';
import { telegramId } from '../../../../config';
import useResponsive from '../../../../hooks/useResponsive';
import Iconify from '../../../../components/Iconify';

// STYLES
const DialogBoxContentContainer = styled(Box)({
  backgroundColor: '#003C79',
  height: 'auto',
  width: '90vw',
  maxWidth: '450px',
  overflow: 'auto',
  margin: 'auto',
  padding: '0!important',
  boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
  position: 'relative',
  borderRadius: '4px!important',
  border: '1px solid #F0C335',
});

const DialogHeaderContainer = styled(Stack)({
  borderBottom: '1px solid #F0C335',
  padding: '16px 24px',
  color: '#F0C335',
});

const CloseButton = styled(Iconify)({
  cursor: 'pointer',
});

const DialogMainContentContainer = styled(Stack)(({ theme }) => ({
  color: '#fff',
  [theme.breakpoints.up('xs')]: {
    padding: '12px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '35px 48px',
  },
}));
// STYLES

const LoginSignupDialog = ({ setOpenForgotPasswordDialog }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const [activeTab, setActiveTab] = useState('1');

  const [authData, setAuthData] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  return (
    <Dialog
      hasClose={false}
      maxWidth={'md'}
      open={isOpenLoginSignupDialog.open}
      onClose={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
      PaperProps={{ sx: { borderRadius: '4px!important', margin: 0 } }}
    >
      <DialogBoxContentContainer>
        <DialogHeaderContainer direction={'row'} justifyContent={'space-between'}>
          <Typography>{translate('welcome_to_bk')}</Typography>
          <CloseButton
            icon={'ep:close'}
            width={24}
            height={24}
            sx={{ cursor: 'pointer', color: '#F0C335' }}
            onClick={() => dispatch(openLoginSignup({ open: false, isLogin: true }))}
          />
        </DialogHeaderContainer>
        <DialogMainContentContainer>
          <Login
            setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
            setActiveTab={setActiveTab}
            setAuthData={setAuthData}
          />
        </DialogMainContentContainer>
      </DialogBoxContentContainer>
    </Dialog>
  );
};

export default LoginSignupDialog;
