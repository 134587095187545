import { InputBase, Stack, TextField, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import palette from '../theme/palette';

export const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '0.4rem',
  padding: '0.2rem 0.4rem',
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 8,
  fontSize: '0.8rem',
  fontWeight: 600,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  input: {
    '&::placeholder': {
      opacity: 0.9,
      color: palette.visitor.input.placeholder,
      fontWeight: 900,
    },
  },
}));

export const CustomOutlinedInput = styled(InputBase)(({ theme }) => ({
  color: '#fff',
  height: 40,
  minHeight: 40,
  padding: '0 12px',
  border: '1px solid rgba(255, 255, 255, .38)',
  borderRadius: 4,
  width: '100%',
  '& input': {
    fontSize: 14,
    fontWeight: 500,
    '&::placeholder': {
      color: 'rgb(255, 255, 255, .35)',
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  // fontSize: '0.8rem',
  // fontWeight: 900,
  width: '100%',
  fontFamily: 'sans-serif',
  flexGrow: 1,
  borderRadius: 4,
  input: {
    color: palette.visitor.input.color,
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 4,
    marginLeft: 4,
    '&::placeholder': {
      opacity: 0.9,
      color: palette.visitor.input.placeholder,
      fontWeight: 500,
      fontSize: 16,
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#c6c6c6',
      borderRadius: 4,
    },
    '&:hover fieldset': {
      borderColor: '#c6c6c6',
    },
    // '&.Mui-focused fieldset': {
    //   borderColor: '#ff0000',
    // },
    // '& .MuiInputAdornment-root svg': {
    //   color: '#ff0000',
    // },
    // '&.Mui-focused .MuiInputAdornment-root svg': {
    //   color: '#ff0000',
    // },
  },
}));

export const ThemeButton = styled(LoadingButton)(({ theme }) => ({
  background: palette.button.bg,
  color: palette.visitor.login.color,
  borderRadius: '4px',
  boxShadow: 'none',
  fontSize: '.875rem',
  border: 0,
  '&:hover': {
    background: palette.button.bg,
    color: palette.visitor.login.color,
  },
}));

export const TableHeadCustomStyle = {
  backgroundColor: 'black',
  borderCollapse: 'separate',
  '& th': {
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
    color: 'white',
  },
  '& th:first-of-type': {
    paddingLeft: 2,
    borderLeft: '1px solid white',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  '& th:last-of-type': {
    paddingRight: 2,
    borderRight: '1px solid white',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
};
