import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  activeTab: '',
  gameCategories: [],
};

const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setActiveTab(state, action) {
      state.activeTab = action?.payload;
    },
    setGameCategories(state, action) {
      state.gameCategories = action?.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setActiveTab, setGameCategories } = slice.actions;
