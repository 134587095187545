import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import TransactionLayout from '../layouts/TransactionLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen loadable={true} />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: <DashboardLayout />,
          children: [
            {
              element: <Home popupAuth={'1'} />,
              index: true,
            },
          ],
        },
        {
          path: 'register',
          element: <DashboardLayout />,
          children: [
            {
              element: (
                <GuestGuard>
                  <Register />
                </GuestGuard>
              ),
              index: true,
            },
          ],
        },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Home Routes
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'games/:categSlug', element: <GamesByCateg /> },
        { path: 'games/:categSlug/:companyId', element: <GamesByCateg /> },
      ],
    },

    // History Routes
    {
      path: 'history',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/history/bet', element: <GameHistory /> },
        { path: '/history/transaction', element: <TransactionHistory /> },
      ],
    },

    // User Routes
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/user/account', element: <AccountProfile /> },
        { path: '/user/banks', element: <MemberBankList /> },
        { path: '/user/wallet', element: <Wallet /> },
        { path: '/user/change-password', element: <ChangePassword /> },
      ],
    },

    // Referral Route
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/referral', element: <Referral /> },
      ],
    },

    // Bonus Route
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/vip', element: <Vip /> },
        { path: '/agent', element: <Agent /> },
      ],
    },

    // Invite Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'invite', element: <Invite /> },
      ],
    },

    // Promotion Routes
    {
      path: '/',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        // </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'promotion', element: <Promotion /> },
        // { path: 'promotion/view/:promotionId', element: <ViewPromotion /> },
      ],
    },

    {
      path: 'payStatus',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: ':paymentMethod',
          element: <PaymentStatus />,
          index: true,
        },
      ],
    },

    // AngPau
    // {
    //   path: '/angpau',
    //   element: (
    //     // <AuthGuard>
    //     <AngPau />
    //     // </AuthGuard>ß
    //   ),
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const ChangePassword = Loadable(lazy(() => import('../pages/auth/ChangePassword')));

// HOME
const Home = Loadable(lazy(() => import('../views/game/Home')));

// GAMES
const GamesByCateg = Loadable(lazy(() => import('../views/game/GamesByCateg')));

// GAME HISTORY
const GameHistory = Loadable(lazy(() => import('../views/history/GameHistory')));

// TRANSACTION HISTORY
const TransactionHistory = Loadable(lazy(() => import('../views/history/TransactionHistory')));

// USER
const AccountProfile = Loadable(lazy(() => import('../views/user/AccountProfile')));
const MemberBankList = Loadable(lazy(() => import('../views/user/MemberBankList')));
const Wallet = Loadable(lazy(() => import('../views/user/Wallet')));

// INVITE
const Invite = Loadable(lazy(() => import('../views/share/Invite')));

// PROMOTION
const Promotion = Loadable(lazy(() => import('../views/promotion/Promotion')));
const ViewPromotion = Loadable(lazy(() => import('../views/promotion/ViewPromotion')));
const AngPau = Loadable(lazy(() => import('../views/promotion/AngPau')));

// PAYMENT STATS
const PaymentStatus = Loadable(lazy(() => import('../pages/PaymentStatus')));

// REFERRAL
const Referral = Loadable(lazy(() => import('../views/referral/Referral')));

// Bonus
const Vip = Loadable(lazy(() => import('../views/referral/Vip')));
const Agent = Loadable(lazy(() => import('../views/referral/Agent')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
