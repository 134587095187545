import DOMPurify from 'dompurify';

export function toSnakeCase(str) {
  return str.replace(/\s+/g, '_').toLowerCase();
}

export function isSnakeCase(str) {
  return /^[a-z]+(_[a-z]+)*$/.test(str);
}

export function getInitials(name) {
  const words = name.split(' ');

  const initials = words.map((word) => word.charAt(0).toUpperCase());

  return initials.join('');
}

export function fToSnake(inputString) {
  if (!inputString) return '';
  try {
    // Convert the string to lowercase
    const lowercaseString = inputString.toLowerCase();

    // Replace spaces, symbols, and consecutive symbols with underscores
    const formattedString = lowercaseString.replace(/[\s,!@#$%^&*()\-_+=<>.?/|\\]+/g, '_');

    return formattedString;
  } catch (error) {
    return inputString;
  }
}

export function isHTML(variable) {
  const element = document.createElement('div');
  element.innerHTML = variable;
  // Check if the parsed content still contains HTML tags
  return element.childElementCount > 0;
}

const options = {
  allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  allowedAttributes: {
    a: ['href'],
  },
  allowedIframeHostnames: ['www.youtube.com'],
};

export const sanitizeHtml = (html) => ({
  __html: DOMPurify.sanitize(html),
});

export const calculateFontSize = (text, baseFontSize = 12, maxLength = 20) => {
  const lengthFactor = Math.min(1, maxLength / text.length);
  const fontSize = baseFontSize * lengthFactor;
  return `${fontSize}px`;
};

export const getOtpErrorMessage = (translate, error) => {
  let errorMessage = error?.response?.data?.message || error?.response?.data || error.message;

  if (error?.code) {
    switch (error?.code) {
      case 'auth/invalid-verification-code':
        errorMessage = translate('invalid_verification');
        break;
      case 'auth/code-expired':
        errorMessage = translate('otp_expired');
        break;
      case 'auth/invalid-phone-number' || 'auth/too-many-requests':
        errorMessage = translate('invalid_x', { x: translate('phone_number') });
        break;
      case 'auth/captcha-check-failed':
        errorMessage = translate('captcha_check_failed');
        break;

      default:
        errorMessage = translate('network_error');
        break;
    }
  }

  return errorMessage;
};