import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Snackbar, Stack, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';

import useAuth from '../../../../hooks/useAuth';
import { useDispatch } from '../../../../redux/store';
import { openLoginSignup } from '../../../../redux/slices/authPages';
import palette from '../../../../theme/palette';
import { StyledTextField, ThemeButton } from '../../../GlobalStyles';

const StyledIconify = styled(Iconify)(({ theme }) => ({
  height: 24,
  width: 24,
  color: palette.visitor.icon,
}));

const ForgotPasswordText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontWeight: '600',
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
  margin: '8px 0px',
  textAlign: 'center',
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontWeight: '600',
  color: palette.visitor.dialog.otherText,
}));

const LabelSpan = styled('span')({
  color: palette.visitor.dialog.span,
  fontSize: '14px',
  fontWeight: '600',
});

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontFamily: 'sans-serif',
  fontWeight: 600,
  color: palette.visitor.dialog.otherText,
  cursor: 'pointer',
  marginBottom: 20,
}));

const LoginButton = styled(ThemeButton)(({ theme }) => ({
  textTransform: 'uppercase',
}));

const _loginForm = {
  userUsername: '',
  userPassword: '',
  captcha: '',
};

const ErrorText = ({ children }) => (
  <span style={{ fontSize: '.75rem', color: palette.visitor.errorText }}>{children}</span>
);

const LinkText = ({ children }) => <span style={{ fontWeight: 600, color: palette.visitor.link }}>{children}</span>;

const Login = ({ setOpenForgotPasswordDialog, setActiveTab }) => {
  const { login } = useAuth();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [loginForm, setLoginForm] = useState(_loginForm);
  const [formError, setFormError] = useState({});
  const [statusMessage, setStatusMessage] = useState({
    open: false,
    message: '',
    type: 'success',
  });
  const [validationErrors, setValidationErrors] = useState(null);

  useEffect(() => {
    isValid(true);

    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [loginForm]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setLoginForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmit = async () => {
    if (!isValid()) return;

    setIsLoading(true);

    const requestData = {
      'user-username': loginForm?.userUsername.trim(),
      'user-password': loginForm?.userPassword.trim(),
    };

    try {
      await login(requestData);

      // close login/register dialog
      dispatch(openLoginSignup({ open: false, isLogin: true }));
    } catch (err) {
      console.log(`Error: ${err.message}`);
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(err.message, snackbarOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const isValid = (allowEmpty = false) => {
    let valid = true;
    const _error = {};

    if (!allowEmpty && loginForm?.userUsername.trim().length < 3) {
      valid = false;
      _error.userUsername = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    } else if (allowEmpty && loginForm?.userUsername.trim() && loginForm?.userUsername.trim().length < 3) {
      valid = false;
      _error.userUsername = translate('invalid_x_must_be_atleast_y_characters', { x: translate('username'), y: '3' });
    } else {
      _error.userUsername = '';
    }

    if (!allowEmpty && loginForm?.userPassword.trim().length < 6) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '6' });
    } else if (allowEmpty && loginForm?.userPassword.trim() && loginForm?.userPassword.trim().length < 6) {
      valid = false;
      _error.userPassword = translate('invalid_x_must_be_atleast_y_characters', { x: translate('password'), y: '6' });
    } else {
      _error.userPassword = '';
    }

    // if (validateCaptcha(loginForm?.captcha?.trim(), false) === true) {
    //   _error.userCaptcha = '';
    // } else {
    //   valid = false;
    //   _error.userCaptcha = translate('captcha_not_match');
    // }

    setFormError((prevState) => _error);

    return valid;
  };

  return (
    <Box>
      {validationErrors && (
        <Alert onClose={() => setValidationErrors(null)} severity="error" sx={{ width: '100%', my: '10px' }}>
          {
            <ul style={{ marginLeft: 10 }}>
              {validationErrors?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
        </Alert>
      )}
      <Box sx={{ mb: '10px' }}>
        <Label fontWeight={600}>
          {translate('username')} <LabelSpan>*</LabelSpan>
        </Label>
        <StyledTextField
          name="userUsername"
          value={loginForm?.userUsername}
          placeholder={translate('fill_up_here')}
          onChange={(e) => handleFormChange(e)}
          InputProps={{
            startAdornment: <StyledIconify icon={'mdi:user-outline'} sx={{ color: '#F0C335' }} />,
          }}
        />
        {formError?.userUsername && <ErrorText>{formError?.userUsername}</ErrorText>}
      </Box>
      <Box>
        <Label fontWeight={600}>
          {translate('password')} <LabelSpan>*</LabelSpan>
        </Label>
        <StyledTextField
          icon
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          name="userPassword"
          value={loginForm?.userPassword}
          placeholder={translate('fill_up_here')}
          onChange={(e) => handleFormChange(e)}
          InputProps={{
            startAdornment: <StyledIconify icon={'material-symbols:lock-outline'} sx={{ color: '#F0C335' }} />,
            endAdornment: (
              <StyledIconify
                icon={showPassword ? 'ph:eye-light' : 'ph:eye-closed-light'}
                sx={{ cursor: 'pointer', color: '#F0C335' }}
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            ),
          }}
        />
        {formError?.userPassword && <ErrorText>{formError?.userPassword}</ErrorText>}
      </Box>
      <Stack direction={'row'} justifyContent={'end'} mt={-1}>
        <ForgotPasswordText onClick={() => setOpenForgotPasswordDialog(true)}>
          {`${translate('forgot_password')}?`}
        </ForgotPasswordText>
      </Stack>

      <LoginButton onClick={() => onSubmit()} fullWidth variant="contained" loading={isLoading}>
        {translate('login')}
      </LoginButton>

      <FooterText
        onClick={() => {
          dispatch(openLoginSignup({ open: false, isLogin: true }));
          navigate('/auth/register');
        }}
        sx={{
          mt: 2,
        }}
      >
        {translate('dont_have_an_account')}? <LinkText>{translate('sign_up')}</LinkText>
      </FooterText>
      {statusMessage?.open && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={statusMessage.open}
          autoHideDuration={3000}
          sx={{ width: 'fit-content' }}
          onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
        >
          <Alert
            onClose={() => setStatusMessage((prevState) => ({ ...prevState, open: false }))}
            severity={statusMessage.type}
            sx={{ width: '100%' }}
          >
            {statusMessage.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

Login.propTypes = {
  setOpenForgotPasswordDialog: PropTypes.func,
  setActiveTab: PropTypes.func,
};

export default Login;
