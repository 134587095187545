import React, { useEffect, useState } from 'react';
// @mui
import { Box, Dialog, Stack, styled, Typography } from '@mui/material';
// hooks
import useLocales from '../../../hooks/useLocales';
// components
import Image from '../../../components/Image';
import { useSelector } from '../../../redux/store';
import Iconify from '../../../components/Iconify';
import useResponsive from '../../../hooks/useResponsive';
import languageIcon from '../../../assets/menu/nav-lang.png';

// STYLES
const LanguageContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  padding: '5px 8px',
  position: 'relative',
  height: '100%',
});

const ImageIcon = styled(Image)({
  width: 25,
  height: 25,
  borderRadius: '50%',
});

const LanguageImageWrapper = styled(Box)(({ theme }) => ({
  width: '36px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  filter:
    'brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(5918%) hue-rotate(357deg) brightness(91%) contrast(99%)',
}));

const LanguageImageText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5',
  // textAlign: 'center',
  textDecoration: 'none',
  color: '#000',
  marginLeft: '5px',
}));

const DialogBoxContentContainer = styled(Box)({
  backgroundColor: '#000',
  borderRadius: '4px',
  height: 'auto',
  width: '90vw',
  maxWidth: '300px',
  overflow: 'auto',
  margin: 'auto',
  boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
  position: 'relative',
});

const DialogHeaderContainer = styled(Stack)({
  backgroundColor: '#000',
  padding: '16px 24px',
});

const CloseButton = styled(Iconify)({
  cursor: 'pointer',
});

const DialogMainContentContainer = styled(Stack)({
  padding: '12px 16px',
  color: '#fff',
});

const LanguageButton = styled(Stack)(({ isSelected }) => ({
  padding: '10px',
  color: '#fff',
  border: isSelected ? '1px solid #F0C335' : '1px solid #c6c6c6',
  cursor: 'pointer',
}));
// STYLES

// ----------------------------------------------------------------------
export default function LanguagePopover({ CustomBtn = null }) {
  const { translate, allLangs, currentLang, onChangeLang } = useLocales();

  const { siteSettings } = useSelector((x) => x.lookup);

  const [customLangs, setCustomLangs] = useState([]);

  const [open, setOpen] = useState(null);

  const [selectedLang, setSelectedLang] = useState(currentLang);

  const domainLocale = process.env.REACT_APP_LOCALE;

  const langStorage = localStorage.getItem('i18nextLng');

  const isMd = useResponsive('up', 'md');

  const isSm = useResponsive('up', 'sm');

  useEffect(() => {
    if (siteSettings?.languages?.length > 0) {
      const matchingLangs = allLangs;
      // .filter((obj1) =>
      //   siteSettings?.languages.some((obj2) => obj1.value === obj2.locale)
      // );

      if (matchingLangs) {
        setCustomLangs(matchingLangs);

        if (matchingLangs.findIndex((ml) => ml.value === langStorage) === -1) {
          handleChangeLang(matchingLangs.find((f) => f.value === domainLocale)?.value);
          console.log('matching');
        }
      } else {
        console.log('No Matching languages');
      }
    }
  }, [siteSettings?.languages]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      {CustomBtn ? (
        React.cloneElement(CustomBtn, { onClick: handleOpen })
      ) : (
        <LanguageContainer
          flex={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={{ xs: 1, sm: 2 }}
          onClick={(e) => handleOpen(e)}
        >
          <ImageIcon disabledEffect src={selectedLang.icon} alt={selectedLang.label} />
          <Stack drirection="column">
            {customLangs.map((option, idx) => (
              <Typography key={idx} fontWeight={700} fontSize={12} color="#fff">
                {option.label}
              </Typography>
            ))}
          </Stack>
        </LanguageContainer>
      )}

      {/* SELECTION DIALOG */}
      <Dialog
        sx={{ borderRadius: '4px' }}
        open={open}
        onClose={() => handleClose(false)}
        PaperProps={{ sx: { borderRadius: '4px!important', margin: 0 } }}
      >
        <DialogBoxContentContainer>
          <DialogHeaderContainer direction={'row'} justifyContent={'space-between'}>
            <Typography>{translate('language')}</Typography>
            <CloseButton
              icon={'ep:close'}
              width={24}
              height={24}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClose(false)}
            />
          </DialogHeaderContainer>
          <DialogMainContentContainer direction={'row'} spacing={2}>
            <Stack
              direction={'row'}
              spacing={{ xs: 1, md: 2 }}
              flex={1}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <ImageIcon disabledEffect src={selectedLang.icon} alt={selectedLang.label} />
              <Typography fontWeight={700} fontSize={16}>
                {process.env.REACT_APP_CURRENCY_CODE}
              </Typography>
            </Stack>
            <Stack direction={isMd ? 'column' : 'row'} flex={2} spacing={1}>
              {customLangs.map((option, idx) => (
                <LanguageButton
                  key={idx}
                  flex={1}
                  justifyContent={'center'}
                  direction={'row'}
                  isSelected={option.value === selectedLang?.value}
                  onClick={() => {
                    handleChangeLang(option.value);
                    setSelectedLang(option);
                  }}
                >
                  <Typography fontWeight={700} fontSize={12}>
                    {option.label}
                  </Typography>
                </LanguageButton>
              ))}
            </Stack>
          </DialogMainContentContainer>
        </DialogBoxContentContainer>
      </Dialog>
      {/* SELECTION DIALOG */}
    </>
  );
}
