/* eslint-disable camelcase */
import { styled } from '@mui/material/styles';
import { Box, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import Image from '../../components/Image';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '.7rem',
}));

const FooterTitle = styled(Typography)({
  fontSize: '1.78rem',
  color: '#ff0000',
});

const FooterCaption = styled(Typography)({
  fontSize: '.7rem',
  marginBottom: '16px',
});

const StyledImage = styled(Image)(({ theme }) => ({
  width: 27,
  height: 31,
  objectFit: 'contain',
}));

const StyledLink = styled(Link)({
  textDecoration: 'underline',
  color: '#fff',
  fontSize: '12px',
});

const CustomScrollbar = styled(Grid)({
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'grey #2b2b2b',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#2b2b2b',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f48fb1',
    borderRadius: '10px',
    border: '2px solid #2b2b2b',
  },
});

const FooterBannerText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: '1rem',
  textAlign: 'center',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  textShadow: '2px 2px 1px black',
  fontWeight: 500,
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5vw',
  },
}));

const Footer = () => {
  const { translate } = useLocales();

  // const displayGameProvidersComponent = (items) => {
  //   const midIndex = Math.ceil(items.length / 2);
  //   const firstHalf = items.slice(0, midIndex);
  //   const secondHalf = items.slice(midIndex);

  //   return (
  //     <CustomScrollbar
  //       container
  //       spacing={1}
  //       sx={{ overflowX: 'scroll', mt: 2, ml: 0, width: '100%', padding: '0 20px 20px 10px' }}
  //     >
  //       <Grid container item xs={12} spacing={1} wrap="nowrap">
  //         {firstHalf.map((item, index) => (
  //           <Grid item>
  //             <Image key={index} src={item} alt="Providers" sx={{ width: '6rem' }} />
  //           </Grid>
  //         ))}
  //       </Grid>
  //       <Grid container item xs={12} spacing={1} wrap="nowrap">
  //         {secondHalf.map((item, index) => (
  //           <Grid item>
  //             <Image key={index} src={item} alt="Providers" sx={{ width: '6rem' }} />
  //           </Grid>
  //         ))}
  //       </Grid>
  //     </CustomScrollbar>
  //   );
  // };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: '#003C79',
          py: '0px !important',
          px: '0px !important',
          pb: '10rem',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Image src={require('../../assets/home/footer_banner.webp')} />
          <FooterBannerText>{translate('footer_banner_desc')}</FooterBannerText>
        </Box>

        <Box sx={{ background: '#003C79', p: '2rem' }}>
          <Stack alignItems="center">
            <Typography sx={{ fontSize: '15px' }}>{translate('official_partner_of')}</Typography>
            <Image
              src={require('../../assets/home/one_championship.png')}
              sx={{ width: '65px', height: '50px', mt: '5px' }}
            />

            <Typography sx={{ fontSize: '15px', mt: '2rem' }}>{translate('payment')}</Typography>
            <Image
              src={require('../../assets/home/local_bank_transfer.png')}
              sx={{ width: '90px', height: '22px', mt: '15px' }}
            />
            <Image src={require('../../assets/home/luckypay.png')} sx={{ width: '44px', height: '38px', mt: '15px' }} />
            <Image src={require('../../assets/home/direpay.png')} sx={{ width: '95px', height: '34px', mt: '15px' }} />
            <Image src={require('../../assets/home/gdpay.png')} sx={{ width: '100px', height: '34px', mt: '15px' }} />
          </Stack>
        </Box>
        <Box sx={{ background: '#003C79', p: '1rem', borderTop: '2rem solid #012951' }}>
          <Typography sx={{ fontWeight: 900, fontSize: '20px' }}>{translate('site_description')}</Typography>
          <ul
            style={{
              marginLeft: 18,
            }}
          >
            <li style={{ marginTop: '15px', lineHeight: 'normal' }}>{translate('site_description_1')}</li>
            <li style={{ marginTop: '15px', lineHeight: 'normal' }}>{translate('site_description_2')}</li>
            <li style={{ marginTop: '15px', lineHeight: 'normal' }}>{translate('site_description_3')}</li>
            <li style={{ marginTop: '15px', lineHeight: 'normal' }}>{translate('site_description_4')}</li>
            <li style={{ marginTop: '15px', lineHeight: 'normal' }}>{translate('site_description_5')}</li>
            <li style={{ marginTop: '15px', lineHeight: 'normal' }}>{translate('site_description_6')}</li>
          </ul>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
