// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game: `খেলা`,
  games: `খেলাগুলি`,
  sport: `খেলাধারা`,
  center: `কেন্দ্র`,
  mines: `মাইনস`,
  crash: `ক্র্যাশ`,
  lucky_colour: `ভাগ্যবান রঙ`,
  dice: `ডাইস`,
  limbo: `লিম্বো`,
  lucky_gift: `ভাগ্যবান উপহার`,
  all_games: `সমস্ত খেলা`,
  game_history: `খেলার ইতিহাস`,
  coupon_code: `কুপন কোড`,
  telegram: `টেলিগ্রাম`,
  customer_service: `গ্রাহক সেবা`,
  player: `খেলোয়াড়`,
  winner: `বিজয়ী`,
  all_bets: `সমস্ত বাজি`,
  my_bets: `আমার বাজি`,
  name: `নাম`,
  time: `সময়`,
  bet_amount: `বাজির পরিমাণ`,
  prediction: `পূর্বাভাস`,
  payout: `পেআউট`,
  login: `লগইন`,
  register: `রেজিস্টার`,
  username_placeholder: `ব্যবহারকারীর নাম`,
  please_input_your_x: `অনুগ্রহ করে আপনার {{x}} ইনপুট করুন`,
  verification_code: `যাচাইকরণ কোড`,
  press_get_code: `যাচাইকরণ কোড পেতে GET CODE বাটনে চাপুন`,
  otp_sent: `যাচাইকরণ কোড প্রেরিত হয়েছে! চালিয়ে যান নীচের যাচাইকরণ কোড প্রবেশ করুন`,
  your_otp_expire: `আপনার OTP {{x}} মিনিট পর্যন্ত প্রযোজ্য থাকবে`,
  get_code: `কোড পান`,
  didnt_receive: `ম্যাসেজ পাচ্ছেন না?`,
  send_again: `আবার পাঠান`,
  captcha: `ক্যাপচা`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন?`,
  minor_note: `এই সাইটে যাওয়ার জন্য নিশ্চিত হন যে আপনি ১৮ বছরের বেশি এবং সম্মত`,
  terms_of_service: `সেবার শর্ত`,
  by_signing_in: 'সাইন ইন বা সাইন আপ করে, আপনি আমাদের সাথে আমাদের',
  dont_have_account: `একাউন্ট নেই?`,
  sign_up: `সাইন আপ`,
  username: `ব্যবহারকারীর নাম`,
  email: `ইমেল`,
  full_name: `পুরো নাম`,
  confirm_18: `আমি নিশ্চিত যে আমি ১৮ বছরের এবং আমি পড়েছি`,
  already_have_account: `ইতিমধ্যে একটি অ্যাকাউন্ট রয়েছে?`,
  share: `শেয়ার`,
  deposit: `আমানত`,
  bonus: `বোনাস`,
  app: `অ্যাপ`,
  load_more: `আরো লোড করুন`,
  details: `বিস্তারিত`,
  helps: `সাহায্য`,
  kyc_policy: `KYC নীতি`,
  privacy_policy: `গোপনীয়তা নীতি`,
  responsible_gaming: `দায়িত্বপূর্ণ গেমিং`,
  terms_and_condition: `শর্তাবলী`,
  faq: `সাধারণ প্রশ্ন`,
  about_us: `আমাদের সম্পর্কে`,
  amount: `পরিমাণ`,
  extra: `অতিরিক্ত`,
  deposit_bonus: `আমানত বোনাস`,
  do_not_participate: `অংশগ্রহণ করবেন না প্রচারে`,
  payment_problem: `পেমেন্ট সমস্যা`,
  click_for_help: `সাহায্যের জন্য ক্লিক করুন`,
  deposit_notes: `আমানত নোট`,
  available_balance: `উপলব্ধ হিসাব`,
  withdraw: `উত্তোলন`,
  withdrawal_notes: `উত্তোলন নোট`,
  withdrawal_note1: `বর্তমানে উত্তোলনের সংখ্যা`,
  withdrawal_note1_1: `ভিপি`,
  withdrawal_note2: `উত্তোলন ফি:`,
  min: `ন্যূনতম:`,
  max: `সর্বাধিক:`,
  opening_times: `খোলার সময়:`,
  withdrawal_note3: `1. ১০ মিনিটের মধ্যে সর্বশ্রেষ্ঠ উত্তোলন সময়`,
  withdrawal_note4: `2. আপনার উত্তোলন তথ্য যথাযথ হলে, যদি উত্তোলন তথ্য ভুল থাকে, তাহলে আপনার অর্থ হারাতে পারে।`,
  withdrawal_note5: `3. আপনার যদি কোনও প্রশ্ন থাকে, তাহলে আমাদের সাথে যোগাযোগ করুন।`,
  notifications: `বিজ্ঞপ্তি`,
  invalid_x: `অবৈধ {{x}}`,
  captcha_not_match: `ক্যাপচা মেলে না`,
  invalid_x_must_be_atleast_y_characters: `অবৈধ {{x}}, অন্তত {{y}} অক্ষর হতে হবে`,
  x_is_required: `{{x}} প্রয়োজন`,
  phone_number: 'ফোন নাম্বার',
  phone_num_x_max: `ফোন নাম্বার সর্বাধিক {{x}} সংখ্যা`,
  email_sent: `ইমেল প্রেরিত, দয়া করে আপনার ইমেল চেক করুন।`,
  enter_valid_email: `দয়া করে একটি বৈধ ইমেল প্রদান করুন।`,
  confirm: `নিশ্চিত করুন`,
  password_atleast_6_characters: `পাসওয়ার্ড অবশ্যই ৬ টি অক্ষরের বেশি হতে হবে।`,
  password_reset_success: `পাসওয়ার্ড রিসেট সাফল্য!.`,
  new_password: `নতুন পাসওয়ার্ড`,
  bank: 'ব্যাংক',
  bank_transfer: `ব্যাংক ট্রান্সফার`,
  bank_name: `ব্যাংকের নাম`,
  bank_branch: `ব্যাংক BSB`,
  pay_id: `পে আইডি`,
  account_name: `অ্যাকাউন্টের নাম`,
  account_number: `অ্যাকাউন্ট নম্বর`,
  drop_or_select: `ফাইল ড্রপ অথবা সিলেক্ট করুন`,
  drop_files_here: `ফাইল এখানে পোঁয়ান`,
  transfer_to: `ট্রান্সফার টু`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোনও প্রমোশন নেই`,
  please_upload_receipt: `দয়া করে রসিদ আপলোড করুন`,
  bank_account: `ব্যাংক অ্যাকাউন্ট`,
  add_account: `অ্যাকাউন্ট যোগ করুন`,
  account_holder_name: `অ্যাকাউন্ট হোল্ডারের নাম`,
  name_of_the_account_holder: `অ্যাকাউন্ট হোল্ডারের নাম`,
  fill_in_bank_account: `ব্যাংক অ্যাকাউন্ট পূরণ করুন`,
  withdraw_information: `উত্তোলন তথ্য`,
  note: `নোট`,
  amount_withdrawn: `উত্তোলন হয়েছে`,
  amount_no_exceed_balance: `উত্তোলনের পরিমাণ উপলব্ধ হিসাবের অধিক হওয়া উচিত নয়`,
  minimum_withdraw_amount_is_x: `ন্যূনতম উত্তোলন পরিমাণ {{x}}`,
  transaction_history: `লেনদেনের ইতিহাস`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ পর্যন্ত`,
  account_name_must_be_4: `অ্যাকাউন্ট নাম ৪ থেকে বেশি হতে হবে`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর ১০-১৪ অক্ষরের হতে হবে`,
  minimum_length_is_x: `ন্যূনতম দৈর্ঘ্য {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য {{x}}`,
  payment_method: `পেমেন্ট পদ্ধতি`,
  mode: `মোড`,
  no_bank_account: `আপনার কোনও অ্যাকাউন্ট উত্তোলনের জন্য নেই, এখন যোগ করুন`,
  please_select_bank: `দয়া করে একটি ব্যাংক অ্যাকাউন্ট নির্বাচন করুন`,
  bet_history: `বাজি ইতিহাস`,
  game_provider: `গেম প্রদায়ক`,
  bet_count: `বাজি গণনা`,
  win_loss: `জয়/ব্যতিত`,
  rebate: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `গেম বিভাগ`,

  you_have_x_unread_messages: `আপনার {{x}} অপঠিত বার্তা আছে`,
  deposit_amount_between_x_and_y: `আমানতের পরিমাণ {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `ন্যূনতম পরিমাণ {{x}} {{y}}`,

  // all
  all: `সব`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `ধরণ`,
  status: `অবস্থা`,
  remark: `মন্তব্য`,
  category: `বিভাগ`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  save: `সংরক্ষণ করুন`,

  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  current_password: `বর্তমান পাসওয়ার্ড`,
  confirm_password: `পাসওয়ার্ড নিশ্চিত করুন`,
  submit: `জমা দিন`,
  password_changed: `পাসওয়ার্ড পরিবর্তিত হয়েছে`,
  x_must_be_y_characters: `{{x}} অন্তত {{y}} টি অক্ষর হতে হবে`,
  reset_password: `পাসওয়ার্ড রিসেট করুন`,
  casino: `ক্যাসিনো`,
  slots: `স্লট`,
  live_casino: `লাইভ ক্যাসিনো`,
  fishing: `মাছ ধরার খেলা`,
  sports: `খেলা`,
  cards: `কার্ড`,
  clear: `স্পষ্ট`,
  search: `অনুসন্ধান`,
  new: `নতুন`,
  logout: `লগ আউট`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং পর্যন্ত উভয় নির্বাচন করুন`,
  from: `থেকে`,
  to: `পর্যন্ত`,
  apply: `প্রয়োগ করুন`,
  promotions: `প্রচার`,
  learn_more: `আরও জানুন`,
  password_doesnt_match: `পাসওয়ার্ড মেলে না`,
  cancel: `বাতিল`,
  confirm_to_pay: `পেমেন্ট নিশ্চিত করুন`,
  deposit_amount: `আমানতের পরিমাণ`,
  loading_please_wait: `লোড হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন`,
  account: `অ্যাকাউন্ট`,
  profile: `প্রোফাইল`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `বোনাস পেতে আপনার জন্মদিন দিন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  referral: `রেফারেল`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `দাবিত লাভ`,
  all_time_profit: `সমস্ত সময় লাভ`,
  referral_note_on_x: `প্রতিটি বন্ধু {{x}} খেলায় খেলার সময় লাভ করুন`,
  tier: `টিয়ার`,
  commission: `কমিশন`,
  referred_member: `রেফারড সদস্য`,
  profit_earned: `লাভ অর্জন করা`,
  account_information: `অ্যাকাউন্ট তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ করুন`,
  how_it_work: `এটা কিভাবে কাজ করে?`,
  invite_note: `আপনার রেফারেল কোড ব্যবহার করে আপনার বন্ধু সাইন আপ করলে এক্সক্লুসিভ রেফারেল বোনাস পান!`,
  share_via: `মাধ্যমে শেয়ার করুন`,
  referral_link: `রেফারেল লিংক`,
  total_profit: `মোট লাভ`,
  copied: `অনুলিপি`,
  nothing_to_claim: `দাবি করার কিছু নেই`,
  claim_history: `দাবি ইতিহাস`,
  downline: `ডাউনলাইন`,
  last_x_digit_bank_ref_number: `ব্যাংক রেফারেন্স নম্বরের শেষ {{x}} টি অংশ`,
  bank_ref_number_validation_x: `ব্যাংক রেফারেন্স নম্বর প্রয়োজন এবং এটি {{x}} অক্ষর দৈর্ঘ্য হতে হবে`,
  sort: 'সাজান',
  login_directly: `সরাসরি লগইন করুন`,
  our_game_provides: `আমাদের গেম প্রদান করে`,
  refer_and_reward: `রেফার এবং পুরস্কার`,
  linked_successfully_x: `{{x}} সাথে সফলভাবে লিঙ্ক করা হয়েছে! অনুগ্রহ করে আপনার অ্যাকাউন্টটি প্রথমবারের জন্য নিবন্ধন করুন`,
  more_x_of_y: `{{x}}/{{y}} এর বেশি`,
  default: `ডিফল্ট`,
  promotion: `প্রচার`,
  daily_mission: 'ডেইলি মিশন',
  agent: 'এজেন্ট',
  contact_us: 'যোগাযোগ করুন',
  download: 'ডাউনলোড',
  cash_claim: 'নগদ দাবি',
  demo: 'ডেমো',
  favorites: 'প্রিয়',
  popular: 'জনপ্রিয়',
  claim_bonus: `বোনাস দাবি`,
  insufficient_balance: `অপর্যাপ্ত হিসাব`,
  currently_displaying: 'বর্তমানে {{x}} {{y}} খেলা {{z}} হইছে',
  all_x: 'সব {{x}}',
  no_results: 'কোনও ফলাফল পাওয়া যায়নি',
  search_games: 'খেলা অনুসন্ধান করুন',
  please_scan_using_your: `অনুগ্রহ করে আপনার ব্যবহার করে স্ক্যান করুন`,
  vip_level: `VIP স্তর`,
  bonus_direct: `বোনাস সরাসরি`,
  rebate_level: `রিবেট স্তর`,
  vip_header: `ভিক্টর৮৮আউসের স্লট মেশিন, বোর্ড এবং কার্ড গেম, ফিশিং, ভিডিও, খেলার দাঁড়ি এবং লটারি গেমে কোনও প্রতিযোগিত্মার জন্য দেয়া টাকা স্থায়ীভাবে যোগ করা হবে। বৈধ বাজি = স্থায়ী আয় এবং টাকার ভাগ্য!`,
  vip_example: `
  উদাহরণ 1: সদস্যরা যারা এক মিলিয়ন টাকার সংগ্রহে পৌঁছেছেন, তারা VIP লেভেল 2 অধিগ্রহণ করে R$210 এবং 3.50% রিবেট বোনাস পাবেন। যখন তারা 6 মিলিয়ন টাকার সংগ্রহে পৌঁছেছেন, তারা VIP লেভেল 3 অধিগ্রহণ করে R$800 এবং 4% রিবেট পাবেন`,
  activity_rules: `কার্যকলাপের নিয়মগুলি:`,
  activity_rules_1: `1. সমস্ত বোনাস অবশ্যই x3 উইনওভার থাকতে হবে উত্তোলনের জন্য`,
  activity_rules_2: `2. দয়া করে সময়ের মধ্যে রিবেট বোনাস দাবি করুন, আপনি দেরি করলে এটি পরিত্যাগ হিসাবে গণ্য হবে;`,
  activity_rules_3: `3. সদস্যদের কার্যকলাপে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণ্য হয়, যদি কোনও আপত্তি থাকে, তবে NextBetBD এর গবেষণার ফলাফল অগ্রাধিকার করবে;`,
  activity_rules_4: `4. আপনি যদি আপনার সদস্য অ্যাকাউন্ট / পাসওয়ার্ড ভুলে গেছেন, তবে "24 ঘণ্টা অনলাইন কাস্টমার সার্ভিস" এ যোগাযোগ করুন আপনার অ্যাকাউন্টের তথ্য পুনরুদ্ধার করার জন্য;`,
  activity_rules_5: `5. NextBetBD এর কাছে এই প্রচারণা পরিবর্তন, বন্ধ বা বাতিল করতে অধিকার রয়েছে।`,
  activity_rules_6: `6. ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন`,
  activity_rules_7: `7. কোনও বোনাসের সাথে যুক্ত করা যাবে না`,
  activity_rules_8: `8. প্রতি ডিপোজিটের সর্বাধিক উত্তোলন R$100,000`,
  event_instructions: 'ইভেন্ট নির্দেশিকা',
  agent_p1: 'অ্যাপ এবং সোশ্যাল মিডিয়াতে আপনার অনন্য লিঙ্ক শেয়ার করুন',
  agent_p2:
    'ফেসবুক, ইনস্টাগ্রাম, টিকটক, টেলিগ্রাম, WhatsApp, YouTube ইত্যাদির মধ্যে হারিয়ে প্রত্যেককে আমন্ত্রণ জানান, যে সদস্যরা আপনার একক লিঙ্কের মাধ্যমে নিবন্ধিত হবেন। কিন্তু লক্ষ্য রাখবেন যে, আপনি যারা নিবন্ধন করছেন, তাদের মনোনীত ও বৈধ হতে হবে।',
  agent_step1:
    'আপনার প্রথম আমন্ত্রণ প্রারম্ভিক জমা সফল হলে, আপনি R$5 এর বোনাস জিততে পারেন। উদাহরণস্বরূপ, আপনি যদি প্রথম জমা করার জন্য 10 জনকে আমন্ত্রণ জানান, তাহলে আপনি R$50 অর্জন করতে পারেন। আপনি যত বেশি লোককে আমন্ত্রণ জানাবেন, ততই বেশি বোনাস পাবেন। আপনি আপনার ইতিহাস দেখতে এবং প্রমোশন পৃষ্ঠায় নিবন্ধন করতে পারেন এবং উচ্চ কমিশন প্রাপ্ত করতে পারেন।',
  agent_step2:
    'আপনার অতিথিরা R$1 বা তার বেশি বাজি করলে, আপনি ইতিমধ্যে উচ্চ কমিশন উপভোগ করতে পারেন! আপনি জীবনকালীন কমিশনে পর্যাপ্ত উপভোগ করতে পারেন এবং জীবনের একটি আমন্ত্রণ! সিস্টেম পূর্বদিনের কমিশনগুলি পরের দিন সকাল 7 টায় সমন্বয় করবে।',
  agent_step2_1: 'কীভাবে পাবেন: লগইন - আমন্ত্রণ প্রচার - কমিশন সংগ্রহ করুন',
  agent_table1: 'স্লট, ফিশিং এবং ব্লকচেনের বেটিং কমিশন হার নিম্নের মতো:',
  agent_table1_1: 'গ্রাহক টপআপ 20 বেট 200',
  person: 'ব্যক্তি',
  agent_table2_title1: 'সদস্যের বেটিং',
  agent_table2_title2: 'শুধু স্লট গেম',
  agent_table2_example:
    'উদাহরণ: আপনার 100 সদস্য আছে এবং দৈনিক মোট বাস্তব মূল্য ধারণ করা হয় 1 মিলিয়ন রিয়াল। সেই দিন আপনি পাবেন: 1,000,000 x 1.20% 12,000 রিয়াল। মাসিক আয় 100,000 অত্যন্ত সহজেই অর্জন করা যায়।',
  reminder: 'মন্তব্য',
  agent_reminder:
    'একই IP, একই ডিভাইস, নাম, ব্যাংক কার্ড এবং অন্যান্য তথ্য সহ একই সদস্য অ্যাকাউন্ট একবার অংশগ্রহণ করতে পারে, এবং যে কেউ যে বিধি ভঙ্গ করলে এই বোনাস থেকে সুবিধা পাবে না। বা গ্রুপ সামগ্রিকভাবে বাতিল হবে এবং অ্যাকাউন্টগুলি স্থায়ীভাবে বরফাত হবে',
  agent_step3_1:
    'সদস্যদের কার্যকলাপে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণ্য হয়, যদি কোনও আপত্তি থাকে, তবে NextBetBD এর গবেষণার ফলাফল অগ্রাধিকার করবে',
  agent_step3_2:
    'আপনি যদি আপনার সদস্য অ্যাকাউন্ট / পাসওয়ার্ড ভুলে গেছেন, তবে আমাদের 24 ঘণ্টা কাস্টমার সার্ভিস দলের সাহায্য নিতে আপনার অ্যাকাউন্টের তথ্য পুনরুদ্ধার করার জন্য যোগাযোগ করুন',
  agent_step3_3: 'NextBetBD এর অধিকার রয়েছে এই প্রচারণাটি পরিবর্তন, বন্ধ বা বাতিল করতে',
  agent_step3_4: 'অ্যাজেন্ট পারফর্মে প্রাপ্ত কমিশন বোনাস টার্ম ক্যাশ বা ওয়ালেটে ক্রেডিটের অবস্থানে',
  agent_step3_5: 'ফ্রি গেম সংরক্ষণ করবেন না, ফ্রি গেম কিনুন',
  agent_step3_6: 'কোনও বোনাস এবং ডিপোজিটের সাথে যুক্ত করা যাবে না',
  agent_step3_7: 'শুধুমাত্র স্লট গেমের জন্য প্রযোজ্য',
  promo_concluded: 'প্রমো শেষ হয়েছে! কাল আবার পর্যন্ত দেখুন!',
  can_only_claim: '{{x}} থেকে {{y}} মাত্র ব্রাজিল সময়ে বোনাস দাবি করা যাবে',
  please_login: 'বোনাস প্রাপ্ত করতে প্রথমে লগইন করুন!',
  back_to_home: 'হোমে ফিরে যান',
  bonus_claimed: 'বোনাস ইতিমধ্যে দাবি করা হয়েছে!',
  terms_n_conditions: `শর্তাদি`,
  claim_now: `এখন দাবি করুন`,
  the_activity_x_in: `কার্যকলাপ {{x}} মধ্যে`,
  starts: `শুরু হয়েছে`,
  ends: `শেষ হয়েছে`,
  list_of_winners: `জয়ীদের তালিকা`,
  days: `দিন`,
  hours: `ঘন্টা`,
  minutes: `মিনিট`,
  seconds: `সেকেন্ড`,
  total_amount_received: `পুরোটাকার মোট প্রাপ্তবত্তা`,
  deposit_fee: `জমা ফি`,
  get: 'পাওয়া',
  apply_now: `এখন প্রয়োগ করুন`,
  number_exists: 'এই ফোন নম্বরটি ইতিমধ্যে নিবন্ধিত!',
  number_not_exists: 'এই ফোন নম্বরটি নিবন্ধিত নেই!',
  payment_draft:
    'আপনি যদি নিশ্চিত হন যে আপনি ইতিমধ্যে পেয়ে গেছেন, তবে দয়া করে অপেক্ষা করুন কারণ পেমেন্টটি এখনও প্রসেসিং হতে পারে',
  my_downline: 'আমার নিম্নলিখিত',
  downline_name: 'নিম্নলিখিত নাম',
  date_joined: 'যোগদানের তারিখ',
  deposit_amount_x_days: 'জমা পরিমাণ ({{x}} দিন)',
  welcome_to_bk: 'স্বাগতম NextBetBD এ',
  dont_have_an_account: `অ্যাকাউন্ট আছে না? `,
  fill_up_here: 'এখানে পূরণ করুন',
  currency_and_language: 'মুদ্রা এবং ভাষা',
  language: 'ভাষা',
  deposit_channel: `জমা চ্যানেল`,
  deposit_details: `জমা বিশদ`,
  bonus_amount: `বোনাস পরিমাণ`,
  main_wallet: `মুখ্য ওয়ালেট`,
  withdraw_details: `উত্তোলন বিশদ`,
  withdraw_amount: `উত্তোলন পরিমাণ`,
  password: 'পাসওয়ার্ড',
  bank_details: `ব্যাংকের বিশদ`,
  add: `যোগ করুন`,
  bank_holder_name: `ব্যাংক হোল্ডার নাম`,
  bank_account_number: `ব্যাংক অ্যাকাউন্ট নাম্বার`,
  mandatory_field: `এটি একটি প্রয়োজনীয় ক্ষেত্র`,
  home: `হোম`,
  password_not_match: 'পাসওয়ার্ড মেলে না',
  payment_methods: 'পেমেন্ট পদ্ধতি',
  customer_support: 'গ্রাহক সমর্থন',
  chat_now: 'এখন চ্যাট করুন',
  certifications: 'সার্টিফিকেশন',
  community_website: 'সম্প্রদায় ওয়েবসাইট',
  gaming_license: 'গেমিং লাইসেন্স',
  footer_title_1: 'NextBetBD অনলাইন ক্যাসিনোর পরিচিতি',
  footer_paragraph_1:
    'অনলাইন গেমিং উত্সাহের এক NextBetBD-এ স্বাগতম - বাংলাদেশের আপনার বিশ্বস্ত অনলাইন ক্যাসিনো! NextBetBD এখানে, আমরা আপনাকে অদ্বিতীয় গেমিং অভিজ্ঞতা সরবরাহ করার জন্য প্রতিশ্রুতিবদ্ধ। এটি শীর্ষ-মানের নিরাপত্তা, রম্য খেলার, এবং এক্সক্লুসিভ পুরস্কার যুক্ত এক অভিজ্ঞতা একত্রিত করে। আসুন জানা যাক আমরা কি আলাদা করে রাখি এবং আমরা অনলাইন মনোরঞ্জনের জন্য আপনার শেষ গন্তব্য কেন।',
  footer_title_2: 'নিরাপত্তা এবং বিশ্বস্ততা',
  footer_paragraph_2:
    'NextBetBD এখানে, আপনার নিরাপত্তাই আমাদের শীর্ষ প্রাথমিক। আমরা একটি প্রযুক্তিবিদ এনক্রিপশন প্রযুক্তি অব্যহতি করি আপনার ব্যক্তিগত এবং আর্থিক তথ্য সুরক্ষিত রাখতে, নিশ্চিত করা হচ্ছে যে আপনি আপনার গেমিং অভিজ্ঞতা মনে সহজ করে উপভোগ করতে পারেন। আমাদের প্ল্যাটফর্ম পূর্ণ লাইসেন্স প্রাপ্ত এবং নিয়মিত অনুসারে পরিচালিত হয়, প্রতিটি খেলায় সত্যতা এবং স্পষ্টতা নিশ্চিত করার জন্য কঠোর মানদণ্ড অনুসরণ করে।',
  footer_title_3: 'জনপ্রিয় গেম নির্বাচন',
  footer_paragraph_3:
    'আমাদের বিস্তৃত গেমের সংগ্রহে একটি উত্তেজনাদায়ক পথযাত্রার জন্য প্রস্তুত হন! ক্লাসিক ক্যাসিনো প্রিয় স্লট, রুলেট, এবং ব্ল্যাকজ্যাক থেকে কাটিং-এজ লাইভ ডিলার অভিজ্ঞতায় প্রস্তুতি, আমরা প্রতিটি ধরনের খেলোয়াড়ের জন্য কিছু অফার করি। আমাদের গেমগুলি শীর্ষস্থানীয় সফটওয়্যার প্রদানকারীদের দ্বারা উন্নত হয়, অবাক করা গ্রাফিক্স, সিমলেস গেমপ্লে, এবং উত্তেজনাদায়ক বোনাস বৈশিষ্ট্যসহ।',
  footer_title_4: 'এক্সক্লুসিভ বোনাস এবং প্রমোশন',
  footer_paragraph_4:
    'NextBetBD এর মূল্যবান সদস্য হিসেবে, আপনি অনেক একটি একটি বোনাস এবং প্রমোশনের সাথে পূর্ণ সম্মান পাবেন। আপনার প্রাথমিক জমা বোনাস বৃদ্ধি করা থেকে চালিত প্রচুর প্রমোশন এবং বিশ্বস্ততার পুরস্কার, আমরা আমাদের খেলোয়াড়দের প্রতিদান করার বিশ্বাস করি। আমাদের প্রচারণার পৃষ্ঠাটি পর্যালোচনা করুন সর্বশেষ অফারের জন্য এবং অতিরিক্ত পুরস্কারের সাথে আপনার গেমিং অভিজ্ঞতা সর্বোত্তম করুন।',
  footer_title_5: 'ব্যবহারকারী-মন্তব্য ইন্টারফেস',
  footer_paragraph_5:
    'আমাদের প্ল্যাটফর্মে চালনা করা সহজ, আপনার সুবিধার জন্য তৈরি করা হয়েছে আমাদের ব্যবহারকারী-মন্তব্য ইন্টারফেস ধন্যবাদ। আপনি যদি ডেস্কটপ, মোবাইল, বা ট্যাবলেট ডিভাইসে আমাদের ক্যাসিনোতে অ্যাক্সেস করেন, আপনি সিমলেস গেমপ্লে এবং হাসল-ফ্রি নেভিগেশন উপভোগ করবেন। আমাদের মতো মূল্যায়ন ইন্টারফেস নিশ্চিত করে যে আপনি যা প্রধান - মজা করা এবং বড় জিতানোর ব্যাপারে মনোযোগ দিতে পারেন।',
  footer_title_6: '24/7 গ্রাহক সমর্থন',
  footer_paragraph_6:
    'সাহায্য প্রয়োজন? আমাদের দায়িত্বশীল গ্রাহক সমর্থন দল 24/7 উপলব্ধ আপনার যেকোনো প্রশ্ন বা সন্দেহ সমাধান করতে। আপনার অ্যাকাউন্ট নিবন্ধন, পেমেন্ট পদ্ধতি, বা খেলার নিয়ম সম্পর্কে প্রশ্ন থাকলে, আমাদের বন্ধুত্বপূর্ণ সমর্থন এজেন্টরা এখানে সহায়তা করার জন্য রয়েছে। লাইভ চ্যাট, ইমেল, বা ফোনে যোগাযোগ করুন এবং আমরা নিশ্চিত করবো যে আপনার গেমিং অভিজ্ঞতা মোটামুটি সমাধানমূলক এবং সহজ থাকে।',
  footer_title_7: 'দায়িত্বশীল গেমিং',
  footer_paragraph_7:
    'আমরা দায়িত্বশীল গেমিং অনুশাসন বৃদ্ধি করতে ও আমাদের খেলোয়াড়দের ভাল থাকার নিশ্চিততা দেওয়ার জন্য প্রতিশ্রুতিবদ্ধ। আমাদের প্ল্যাটফর্মে আপনার গেমিং কার্যক্রম সম্পর্কে পরিচালনা করার জন্য বিভিন্ন সরঞ্জাম এবং সম্পদ রয়েছে। জমা সীমা নির্ধারণ করার থেকে স্ব-অবস্থান বিকল্পগুলির জন্য, আমরা আপনাকে আপনার গেমিং অভ্যন্তরীণ অধিকার রক্ষা করার সম্ভাবনা করি।',
  footer_title_8: 'লাইসেন্স সম্পূর্ণ অনুসারে লাইসেন্সপ্রাপ্ত অনলাইন ক্যাসিনো বাংলাদেশ',
  footer_paragraph_8:
    'বাংলাদেশের আমাদের NextBetBD প্রাধিকৃত ক্যাসিনো প্ল্যাটফর্মে অনলাইন গেমিং উৎসাহ অন্বেষণ করুন। ক্লাসিক স্লট থেকে সম্মর্থনশীল লাইভ ডিলার অভিজ্ঞতা পর্যন্ত, উত্তেজনাদায়ক গেমের বিশাল প্রকার আবিষ্কার করুন। এক্সক্লুসিভ বোনাস এবং অদ্বিতীয় মনোরঞ্জনের জন্য এখন যোগদান করুন। দায়িত্বশীলভাবে খেলুন। 18+ হতে হবে। শর্তাবলী প্রয়োজন।',
  footer_title_9: 'NextBetBD সর্বোত্তম অনলাইন ক্যাসিনো অভিজ্ঞতা উপহার',
  footer_paragraph_9:
    'NextBetBD এবার বাংলাদেশের আপনার বিশ্বস্ত অনলাইন ক্যাসিনোতে অনলাইন গেমিং মনোরঞ্জনের শীর্ষে। নিরাপত্তা, উত্তেজনা, এবং পুরস্কারে কেন্দ্রিত, আমরা আপনার গেমিং অভিজ্ঞতাকে নতুন উচ্চতায় উত্তীর্ণ করতে এখানে রয়েছি। আজই যোগদান করুন এবং জানুন কেন আমরা বাংলাদেশের জনপ্রিয় খেলোয়াড়দের জন্য পছন্দিত পথ হয়ে উঠেছি। খেলা শুরু করা যাক!',
  footer_copyright: 'কপিরাইট © 2024 NextBetBD. সমস্ত অধিকার সংরক্ষিত।',
  currency: 'মুদ্রা',
  fill_up_password_here: 'এখানে পাসওয়ার্ড পূরণ করুন',
  betting_records: 'বেটিং রেকর্ড',
  turn_over: 'টার্ন ওভার',
  transfer_records: 'ট্রান্সফার রেকর্ড',
  transaction_records: 'লেনদেন রেকর্ড',
  funds: 'তহবিল',
  history: 'ইতিহাস',
  claim_voucher: 'ভাউচার দাবি করুন',
  check_in_lucky: 'চেক-ইন / ভাগ্যশালী চাকা',
  personal_info: 'ব্যক্তিগত তথ্য',
  inbox_messages: 'ইনবক্স মেসেজ',
  referral_bonus: 'রেফারেল বোনাস',
  support: '24/7 সমর্থন',
  whatsapp: 'WhatsApp',
  facebook: 'ফেসবুক',
  downloads: 'ডাউনলোড',
  download_android: 'অ্যান্ড্রয়েড ডাউনলোড করুন',
  overview: 'ওভারভিউ',
  referral_program: 'রেফারেল প্রোগ্রাম',
  summary: 'সংক্ষিপ্তসার',
  referral_report: 'রেফারেল রিপোর্ট',
  redeem_history: 'রিডিম হিস্ট্রি',
  commission_to_redeem: 'রিডিমের জন্য কমিশন',
  today_commission: 'আজকের কমিশন',
  this_month_commission: 'এই মাসের কমিশন',
  referral_tier: 'রেফারেল টিয়ার',
  total: 'মোট',
  share_now: 'এখন ভাগাড়',
  back: 'পিছনে',
  complete: 'সম্পূর্ণ',
  next: 'পরবর্তী',
  c_bet_amount: 'বেট পরিমাণ',
  c_rebate: 'রিবেট',
  c_turnover: 'টার্নওভার',
  c_amount: 'পরিমাণ',
  c_bonus: 'বোনাস',
  c_remarks: 'মন্তব্য',
  level: 'স্তর',
  no_promotions: 'কোন প্রচার নেই',
  dont_show_again: 'আবার দেখাবেন না',
  close: 'বন্ধ করুন',
  crash_game: `ক্র্যাশ গেম`,
  maximum_withdraw_amount_is_x: `সর্বাধিক উত্তোলনের পরিমাণ হল {{x}}`,
  no_balance: `ব্যালেন্স নেই`,
  no: `না`,
  optional: `অপশনাল`,
  network_error: `নেটওয়ার্ক ত্রুটি`,
  game_providers: `গেম প্রদানকারী`,
  sub_filters: `সাব ফিল্টার`,
  balance: 'ভারসাম্য',
  requirement: 'প্রয়োজনীয়তা',
  deposit_from_bank: 'ব্যাংক থেকে জমা',
  footer_banner_desc: `আমাদের 24/7 লাইভ বেটিং-এ বিভিন্ন ধরনের খেলাধুলা এবং সীমাহীন মজার ভার্চুয়াল থেকে সেরা সম্ভাবনাগুলি পান`,
  official_partner_of: `এর অফিসিয়াল পার্টনার`,
  site_description: `Nextbetbd.com-এ স্বাগতম, এশিয়ার অন্যতম শীর্ষস্থানীয় অনলাইন বেটিং এবং গেমিং সাইট।`,
  site_description_1: `নেক্সটবিডি বিভিন্ন ধরণের ক্রীড়া বাজার এবং বিশ্বব্যাপী ক্রীড়া ইভেন্ট অফার করে, 15,000টিরও বেশি লাইভ স্পোর্টস ইভেন্টের উপর বাজি ধরার জন্য গর্বিত। লাইভ ডিলার, স্লট, আর্কেড গেমস এবং আমাদের সমস্ত পণ্য জুড়ে আমাদের উদার প্রচারের সাথে একত্রিত, আমরা প্রতিশ্রুতি দিচ্ছি যে আপনি বিশ্বের সর্বোচ্চ শ্রেণীর অনলাইন গেমিং বিনোদন উপভোগ করবেন।`,
  site_description_2: `NextBetBD এ, আপনার নিরাপত্তা আমাদের সর্বোচ্চ অগ্রাধিকার। আমরা আপনার ব্যক্তিগত এবং আর্থিক তথ্য সুরক্ষিত করার জন্য অত্যাধুনিক এনক্রিপশন প্রযুক্তি ব্যবহার করি, যাতে আপনি মনের শান্তির সাথে আপনার গেমিং অভিজ্ঞতা উপভোগ করতে পারেন। আমাদের প্ল্যাটফর্মটি সম্পূর্ণ লাইসেন্সপ্রাপ্ত এবং নিয়ন্ত্রিত, প্রতিটি খেলায় ন্যায্যতা এবং স্বচ্ছতা নিশ্চিত করার জন্য কঠোর মান মেনে চলে।`,
  site_description_3: `NextBetBD-এর একজন মূল্যবান সদস্য হিসেবে, আপনি প্রচুর পরিমাণে একচেটিয়া বোনাস এবং প্রচার পাবেন। স্বাগত বোনাসগুলি যা আপনার প্রাথমিক আমানতগুলিকে চলমান প্রচার এবং আনুগত্য পুরষ্কারগুলিকে বাড়িয়ে তোলে, আমরা আমাদের খেলোয়াড়দের উদারভাবে পুরস্কৃত করতে বিশ্বাস করি। সর্বশেষ অফারগুলির জন্য আমাদের প্রচার পৃষ্ঠায় নজর রাখুন এবং অতিরিক্ত পুরষ্কার সহ আপনার গেমিং অভিজ্ঞতাকে সর্বাধিক করুন৷`,
  site_description_4: `আমাদের প্ল্যাটফর্ম নেভিগেট করা একটি হাওয়া, আপনার সুবিধার কথা মাথায় রেখে ডিজাইন করা আমাদের ব্যবহারকারী-বান্ধব ইন্টারফেসের জন্য ধন্যবাদ। আপনি ডেস্কটপ, মোবাইল বা ট্যাবলেট ডিভাইসে আমাদের ক্যাসিনো অ্যাক্সেস করছেন না কেন, আপনি বিরামহীন গেমপ্লে এবং ঝামেলা-মুক্ত নেভিগেশন উপভোগ করবেন। আমাদের স্বজ্ঞাত ইন্টারফেস নিশ্চিত করে যে আপনি সবচেয়ে গুরুত্বপূর্ণ বিষয়গুলিতে ফোকাস করতে পারেন - মজা করা এবং বড় জয়!`,
  site_description_5: `সাহায্য প্রয়োজন? আমাদের ডেডিকেটেড কাস্টমার সাপোর্ট টিম আপনার যেকোন প্রশ্ন বা উদ্বেগের সমাধানের জন্য 24/7 উপলব্ধ। অ্যাকাউন্ট রেজিস্ট্রেশন, অর্থপ্রদানের পদ্ধতি বা খেলার নিয়ম সম্পর্কে আপনার প্রশ্ন থাকুক না কেন, আমাদের বন্ধুত্বপূর্ণ সহায়তা এজেন্ট সাহায্য করতে এখানে রয়েছে। লাইভ চ্যাট, ইমেল বা ফোনের মাধ্যমে আমাদের সাথে যোগাযোগ করুন এবং আমরা নিশ্চিত করব যে আপনার গেমিং অভিজ্ঞতা মসৃণ এবং উপভোগ্য থাকে।`,
  site_description_6: `বাংলাদেশে আপনার বিশ্বস্ত অনলাইন ক্যাসিনো NextBetBD এর সাথে অনলাইন গেমিং বিনোদনের চূড়ান্ত অভিজ্ঞতা নিন। নিরাপত্তা, উত্তেজনা এবং পুরষ্কারের উপর ফোকাস সহ, আমরা আপনার গেমিং অভিজ্ঞতাকে নতুন উচ্চতায় উন্নীত করতে এখানে আছি। আজই আমাদের সাথে যোগ দিন এবং আবিষ্কার করুন কেন আমরা সারা বাংলাদেশে বিচক্ষণ খেলোয়াড়দের পছন্দের পছন্দ। খেলা শুরু করা যাক!`,
  payment: `পেমেন্ট`,
  vip_faq: `ভিআইপি সদস্যপদ FAQ`,
  vip_faq_1: `কিভাবে একজন Nextbetbd ভিআইপি সদস্য হবেন?`,
  vip_faq_2: `কোন ঝামেলা, কোন অতিরিক্ত খরচ!`,
  vip_faq_3: `সহজভাবে Nextbetbd এ সদস্য হিসাবে নিবন্ধন করুন, জমা করুন এবং বাজি রাখা শুরু করুন।`,
  vip_faq_4: `আপগ্রেডগুলি প্রতিটি স্তরের জন্য আমানতের প্রয়োজনীয়তা পূরণ করার পরে স্বয়ংক্রিয় হয়, আপনাকে তাত্ক্ষণিকভাবে ভিআইপি সুবিধাগুলি উপভোগ করতে দেয়`,
  vip_faq_5: `আমি কিভাবে আপগ্রেড পেতে পারি?`,
  vip_faq_6: `নির্ধারিত সময়সীমার মধ্যে আমানতের প্রয়োজনীয়তা পূরণ করুন।`,
  vip_faq_7: `যোগ্য ভিআইপি স্তরে স্বয়ংক্রিয় আপগ্রেড`,
  vip_faq_8: `ভিআইপি স্তরের কোন স্কিপিং; স্তর এক সময়ে এক অগ্রগতি হয়.`,
  vip_faq_9: `নির্দিষ্ট আমানতের প্রয়োজনীয়তার জন্য ভিআইপি স্তরের চার্ট পড়ুন।`,
  vip_faq_10: `আমি কিভাবে আপগ্রেড বোনাস পেতে পারি?`,
  vip_faq_11: `আপগ্রেড বোনাস আপগ্রেড করার সাথে সাথেই প্রদান করা হয়`,
  vip_faq_12: `প্রতিটি স্তর আপগ্রেড বোনাস একবার দাবি করা যেতে পারে।`,
  vip_faq_13: `30 দিনের মধ্যে প্রতিটি আমানতের প্রয়োজনীয়তা পূরণ করে একাধিক স্তরের আপগ্রেড করা সম্ভব।`,
  vip_faq_14: `আমানতের পরিমাণ কিভাবে নির্ধারণ করা হয়?`,
  vip_faq_15: `সঞ্চিত আমানতের উপর ভিত্তি করে।`,
  vip_faq_16: `পূর্ববর্তী আপগ্রেড থেকে 30 দিনের মধ্যে পরবর্তী স্তরের জমার প্রয়োজনীয়তা পূরণ করে আপগ্রেডগুলি অনুসরণ করতে হবে৷`,
  vip_faq_17: `টাইমলাইন প্রতিটি সদস্যের জন্য অনন্য।`,
  vip_faq_18: `কেন আমার ভিআইপি লেভেল ডাউনগ্রেড করা হয়?`,
  vip_faq_19: `ধরে রাখার প্রয়োজনীয়তা পূরণে ব্যর্থতার ফলে ডাউনগ্রেড হয়।`,
  vip_faq_20: `আপনার ভিআইপি স্তর এবং সুবিধাগুলি ধরে রাখতে, মাসিক ধরে রাখার পরিমাণ অর্জন করুন।`,
  vip_faq_21: `উদাহরণ: ভিআইপি সিলভার হিসাবে থাকার জন্য, ৳62,500 এর মাসিক জমা জমা রাখুন`,
  vip_tnc: `ভিআইপি সদস্যপদ শর্তাবলী`,
  vip_tnc_1: `Nextbetbd কোনো পূর্ব নোটিশ দিয়ে বা ছাড়াই সময়ে সময়ে রিডেম্পশন বা এর কোনো অংশ বা প্রযোজ্য শর্তাবলীর যেকোনো অংশ পরিবর্তন, বাতিল, বন্ধ বা স্থগিত করার অধিকার সংরক্ষণ করে।`,
  vip_tnc_2: `ভিআইপি প্রোগ্রামে অংশগ্রহণকারী সদস্যদের অবশ্যই নেক্সটবেটবিডি দ্বারা প্রয়োগ করা ওয়েবসাইটের সমস্ত প্রাসঙ্গিক নিয়ম ও শর্তাবলী মেনে নিতে হবে এবং উপরোক্ত নিয়ম ও শর্তাবলী মেনে চলতে হবে।`,
  vip_tnc_3: `ভিআইপি মেম্বারশিপের শর্তাবলী প্রযোজ্য।`,
  vip_benefits: `ভিআইপি প্রয়োজনীয়তা এবং সুবিধা`,
  vip_upgrade: `ভিআইপি আপগ্রেড`,
  monthly_deposit_requirement: `মাসিক জমার প্রয়োজন`,
  vip: `ভিআইপি সুবিধা`,
  upgrade_bonus: `বোনাস আপগ্রেড করুন`,
  birthday_bns: `জন্মদিন বোনাস`,
  merch_and_gifts: `ভিআইপি পণ্যদ্রব্য এবং বিশেষ উপহার`,
  vip_cs: `এক্সপ্রেস ভিআইপি গ্রাহক সমর্থন`,
  vip_priority: `আমানত এবং উত্তোলন অগ্রাধিকার`,
  vip_events: `ভিআইপি ইভেন্ট এবং অভিজ্ঞতা`,
  vip_exclusive_access: `নতুন বৈশিষ্ট্যগুলিতে একচেটিয়া অ্যাক্সেস`,
  dedicated_manager: `ডেডিকেটেড ভিআইপি ম্যানেজার`,
  invited_only: `শুধুমাত্র আমন্ত্রিত`,
  become_vip: `BE Nextbetbd ভিআইপি`,
  become_vip_1: `একচেটিয়া অফার, জমকালো উপহার, উদার বোনাস এবং স্বাভাবিকভাবেই সর্বোচ্চের জন্য আমাদের ভিআইপি সদস্য হন
              অগ্রাধিকার পরিষেবার স্তর।`,
  become_vip_2: `আপনার মর্যাদা উন্নত করুন এবং আমাদের সাথে অতুলনীয় পরিষেবার অভিজ্ঞতা নিন!`,
  become_vip_3: `Nextbetbd ভিআইপি সদস্যপদে কিভাবে যোগদান করবেন?`,
  become_vip_4: `কোন ঝামেলা নেই, অপেক্ষা নেই - তাত্ক্ষণিক আপগ্রেড!`,
  become_vip_5: `স্বয়ংক্রিয় আপগ্রেড: ভিআইপি হওয়ার জন্য আমানতের প্রয়োজনীয়তা পূরণ করুন।`,
  become_vip_6: `*আরো বিস্তারিত জানার জন্য নিচের টেবিলটি দেখুন।`,
  become_vip_7: `অবিলম্বে ভিআইপি সুবিধা উপভোগ করুন!`,
  vip_retention_requirement: `ভিআইপি ধরে রাখার প্রয়োজনীয়তা`,
  monthly_accumulated_deposit: `মাসিক সঞ্চিত আমানত`,
  membership_renewal: `সদস্যপদ নবায়ন`,
};

export default en;
