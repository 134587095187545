import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, Box, Typography, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import { useSnackbar } from 'notistack';
// components
import { FormProvider, RHFPassword } from '../../../components/hook-form';
import conObj from '../../../utils/connection-assistant';
import { useDispatch } from '../../../redux/store';
import { closeChangePwd } from '../../../redux/slices/authPages';
import useLocales from '../../../hooks/useLocales';

// STYLES
const FieldContainer = styled(Stack)({
  color: '#000',
  backgroundColor: '#b9b9b9',
  padding: '15px',
});

const StyledButton = styled(LoadingButton)({
  backgroundColor: '#F0C335',
  padding: '5px 15px',
  color: '#000',
  margin: '12px 34px',
  width: '-webkit-fill-available',
});
// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { translate } = useLocales();

  const ChangePwdSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, translate('x_must_be_y_characters', { x: translate('current_password'), y: 6 }))
      .required(translate('x_is_required', { x: translate('current_password') })),
    newPassword: Yup.string()
      .min(6, translate('x_must_be_y_characters', { x: translate('new_password'), y: 6 }))
      .required(translate('x_is_required', { x: translate('new_password') })),
    confirmNewPassword: Yup.string().when('newPassword', {
      is: (val) => !!val,
      then: Yup.string()
        .nullable()
        .oneOf([Yup.ref('newPassword')], translate('password_doesnt_match'))
        .min(6, translate('x_must_be_y_characters', { x: translate('confirm_password'), y: 6 }))
        .required(translate('x_is_required', { x: translate('confirm_password') })),
    }),
  });

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePwdSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await conObj.post('password.json', {
        'user-current_password': data?.currentPassword,
        'user-new_password': data?.newPassword,
        'user-new_password_confirmation': data?.confirmNewPassword,
      });
      reset();
      dispatch(closeChangePwd());
      enqueueSnackbar(translate('password_changed'));
    } catch (error) {
      reset();
      const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
      enqueueSnackbar(error.message, snackbarOptions);
      setError('afterSubmit', { ...error, message: error.message });
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={0.1} sx={{ width: '100%', flexShrink: 0 }}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
          <FieldContainer direction={'row'} alignItems={'center'}>
            <Box flex={1}>
              <Typography>{translate('current_password')}</Typography>
            </Box>
            <Box flex={3}>
              <RHFPassword name={'currentPassword'} size="small" placeholder={translate('fill_up_password_here')} />
            </Box>
          </FieldContainer>

          <FieldContainer direction={'row'} alignItems={'center'}>
            <Box flex={1}>
              <Typography>{translate('new_password')}</Typography>
            </Box>
            <Box flex={3}>
              <RHFPassword name={'newPassword'} size="small" placeholder={translate('fill_up_password_here')} />
            </Box>
          </FieldContainer>

          <FieldContainer direction={'row'} alignItems={'center'}>
            <Box flex={1}>
              <Typography>{translate('confirm_password')}</Typography>
            </Box>
            <Box flex={3}>
              <RHFPassword name={'confirmNewPassword'} size="small" placeholder={translate('fill_up_password_here')} />
            </Box>
          </FieldContainer>
          <Box>
            <StyledButton variant="contained" type="submit" loading={isSubmitting}>
              {translate('submit')}
            </StyledButton>
          </Box>
        </Stack>
      </FormProvider>
    </Box>
  );
}
