import React, { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Drawer, Grid, Stack, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';

import useLocales from '../../../hooks/useLocales';
import useAuth from '../../../hooks/useAuth';
import { useSelector } from '../../../redux/store';
import useResponsive from '../../../hooks/useResponsive';
import { getUserMemberBalance } from '../../../redux/slices/lookup';

// STYLES
const DrawerContent = styled(Box)({
  backgroundColor: '#003C79',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '8px',
});

const CardContainer = styled(Box)({
  backgroundColor: '#000',
});

const CardHeaderContainer = styled(Box)({
  padding: '12px 8px',
});

const CardHeaderText = styled(Typography)({
  paddingLeft: '8px',
  borderLeft: '4px solid #0075EC',
  lineHeight: 1.2,
  fontSize: '14px',
});

const CardBodyContainer = styled(Grid)({
  padding: '8px',
});

const CardButton = styled(Grid)({
  cursor: 'pointer',
  padding: '10px',
});

const CardIcon = styled(Image)({
  width: 36,
  height: 36,
  display: 'block',
  margin: 'auto',
});

const CardLabel = styled(Typography)({
  textAlign: 'center',
  fontSize: '11px',
  marginTop: '3px',
});

const Label = styled(Typography)({
  color: '#fff',
  fontSize: '12px',
});

const Balance = styled(Typography)({
  color: '#fff',
  fontSize: '18px',
});

const StyledIcon = styled(Iconify)({
  color: '#F0C335',
  fontSize: '24px',
  cursor: 'pointer',
});
// STYLES

const AccountDrawer = ({ open, setOpen }) => {
  const isDesktop = useResponsive('up', 'md');

  const { translate } = useLocales();
  const { userProfile, getAccountProfile, logout } = useAuth();
  const { memberBalance, siteSettings } = useSelector((x) => x.lookup);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showWallet, setShowWallet] = useState(false);
  const [isDisabledRefresh, setIsDisabledRefresh] = useState(false);

  useEffect(() => {
    getAccountProfile();
  }, []);

  const handleRefreshBalance = () => {
    if (isDisabledRefresh) {
      enqueueSnackbar('Please wait 5 seconds before trying again.', { variant: 'warning' });
    } else {
      setIsDisabledRefresh(true);
      getUserMemberBalance();

      setTimeout(() => {
        setIsDisabledRefresh(false);
      }, 5000);
    }
  };

  const balanceDisplay = useMemo(() => {
    const rawBalance = memberBalance?.displayBalance !== '0' ? memberBalance?.displayBalance : '0.00';

    if (showWallet) {
      return rawBalance;
    }

    return rawBalance.toString().replace(/\d/g, '*');
  }, [showWallet, memberBalance]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });
      setOpen(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const openSocial = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer}
      sx={{ width: '100%', height: '100%', overflowY: 'auto', backgroundColor: '#ffeff6' }}
      PaperProps={{
        sx: {
          top: 0,
        },
      }}
    >
      <Box
        sx={
          isDesktop
            ? { display: 'block', margin: 'auto', height: '100%', overflowX: 'hidden' }
            : { height: '100%', overflowX: 'hidden' }
        }
      >
        <Box
          sx={
            isDesktop
              ? { minWidth: { md: '600px', lg: '900px', height: '100%', backgroundColor: 'yellow' } }
              : { height: '100%' }
          }
        >
          <Box sx={{ position: 'relative', height: '150px' }}>
            <Image
              src={require('../../../assets/profile/member-header-bg.png')}
              sx={{ height: '150px', objectFit: 'cover' }}
            />
            <Stack
              direction={'row'}
              sx={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, padding: '30px' }}
              flex={1}
              alignItems={'center'}
              spacing={2}
            >
              <Box
                sx={{
                  backgroundColor: '#000',
                  borderRadius: '50%',
                  width: 84,
                  height: 84,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '44px',
                  color: '#FFB90F',
                  fontWeight: 500,
                }}
              >
                {userProfile?.username.charAt(0).toUpperCase()}
              </Box>
              <Typography sx={{ color: '#000' }}>{userProfile?.username}</Typography>
            </Stack>
            <Stack
              direction={'row'}
              sx={{
                position: 'absolute',
                top: -32,
                right: -32,
                padding: '30px',
                backgroundColor: '#d5d5d5',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
              flex={1}
              alignItems={'center'}
              justifyContent={'center'}
              onClick={() => setOpen(false)}
            >
              <Iconify
                icon="material-symbols:close"
                sx={{ fontSize: '24px', fontWeight: 'bold', margin: '10px 10px -10px -10px' }}
              />
            </Stack>
          </Box>
          <DrawerContent pt={1}>
            <Stack direction={'column'} spacing={1}>
              <CardContainer sx={{ padding: '16px 4px' }}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Label>{translate('main_wallet')}</Label>
                    <StyledIcon icon={'tabler:refresh'} onClick={handleRefreshBalance} />
                    <StyledIcon
                      icon={showWallet ? 'eva:eye-off-fill' : 'eva:eye-fill'}
                      onClick={() => setShowWallet(!showWallet)}
                    />
                  </Stack>
                  <Balance>
                    {memberBalance?.currencyCode} {balanceDisplay}
                  </Balance>
                </Stack>
              </CardContainer>
              {/* FUNDS */}
              <CardContainer>
                <CardHeaderContainer>
                  <CardHeaderText>{translate('funds')}</CardHeaderText>
                </CardHeaderContainer>
                <Divider />
                <CardBodyContainer container sx={{ padding: '8px' }}>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/user/wallet');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-deposit.png')} />
                    <CardLabel>{translate('deposit')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/user/wallet');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-withdrawal.png')} />
                    <CardLabel>{translate('withdraw')}</CardLabel>
                  </CardButton>
                </CardBodyContainer>
              </CardContainer>
              {/* HISTORY */}
              <CardContainer>
                <CardHeaderContainer>
                  <CardHeaderText>{translate('history')}</CardHeaderText>
                </CardHeaderContainer>
                <Divider />
                <CardBodyContainer container sx={{ padding: '8px' }}>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/history/bet');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-total.png')} />
                    <CardLabel>{translate('betting_records')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/history/transaction');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-transaction.png')} />
                    <CardLabel>{translate('transaction_records')}</CardLabel>
                  </CardButton>
                </CardBodyContainer>
              </CardContainer>
              {/* PROFILE */}
              <CardContainer>
                <CardHeaderContainer>
                  <CardHeaderText>{translate('profile')}</CardHeaderText>
                </CardHeaderContainer>
                <Divider />
                <CardBodyContainer container sx={{ padding: '8px' }}>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/user/account');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-info.png')} />
                    <CardLabel>{translate('personal_info')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/user/change-password');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-reset.png')} />
                    <CardLabel>{translate('change_password')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/user/banks');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-info.png')} />
                    <CardLabel>{translate('bank_details')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => {
                      navigate('/referral');
                      toggleDrawer();
                    }}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-referral.png')} />
                    <CardLabel>{translate('referral')}</CardLabel>
                  </CardButton>
                </CardBodyContainer>
              </CardContainer>
              {/* CONTACT US */}
              <CardContainer>
                <CardHeaderContainer>
                  <CardHeaderText>{translate('contact_us')}</CardHeaderText>
                </CardHeaderContainer>
                <Divider />
                <CardBodyContainer container sx={{ padding: '8px' }}>
                  <CardButton
                    xs={3}
                    onClick={() => siteSettings?.config_whatsapp !== '' && openSocial(siteSettings.config_whatsapp)}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-whatsapp.png')} />
                    <CardLabel>{translate('whatsapp')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => siteSettings?.config_telegram !== '' && openSocial(siteSettings.telegram)}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-telegram.png')} />
                    <CardLabel>{translate('telegram')}</CardLabel>
                  </CardButton>
                  <CardButton
                    xs={3}
                    onClick={() => siteSettings?.config_facebook !== '' && openSocial(siteSettings.config_facebook)}
                  >
                    <CardIcon src={require('../../../assets/profile/icon-fb.png')} />
                    <CardLabel>{translate('facebook')}</CardLabel>
                  </CardButton>
                </CardBodyContainer>
              </CardContainer>
              <CardContainer sx={{ padding: '12px', backgroundColor: '#F0C335', marginTop: '20px' }}>
                <Stack direction={'row'} justifyContent={'center'} spacing={1} onClick={handleLogout}>
                  <Image sx={{ width: 28, height: 28 }} src={require('../../../assets/profile/icon-logout.png')} />
                  <Typography sx={{ fontSize: '16px', color: '#003C79' }}>{translate('logout')}</Typography>
                </Stack>
              </CardContainer>
            </Stack>
          </DrawerContent>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AccountDrawer;
