// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  game: `Game`,
  games: `Games`,
  sport: `Sport`,
  center: `Center`,
  mines: `MINES`,
  crash: `CRASH`,
  lucky_colour: `LUCKY COLOUR`,
  dice: `DICE`,
  limbo: `LIMBO`,
  lucky_gift: `LUCKY GIFT`,
  all_games: `All Games`,
  game_history: `Game History`,
  coupon_code: `Coupon Code`,
  telegram: `Telegram`,
  customer_service: `Customer Service`,
  player: `Player`,
  winner: `Winner`,
  all_bets: `All Bets`,
  my_bets: `My Bets`,
  name: `Name`,
  time: `Time`,
  bet_amount: `Bet Amount`,
  prediction: `Prediction`,
  payout: `Payout`,
  login: `Login`,
  register: `Register`,
  username_placeholder: `Username`,
  please_input_your_x: `Please Input Your {{x}}`,
  verification_code: `Verification Code`,
  press_get_code: `Press GET CODE Button To Receive Verification Code Via SMS`,
  otp_sent: `Verification Code Sent! Enter the verification code below to continue`,
  your_otp_expire: `Your OTP will expire in`,
  get_code: `GET CODE`,
  didnt_receive: `Didn't receive the message?`,
  send_again: `Send Again`,
  captcha: `CAPTCHA`,
  forgot_password: `Forgot Password`,
  minor_note: `To visit this site, make sure you are over 18 and agree`,
  terms_of_service: `Terms of Service`,
  by_signing_in: 'By Signing In or Signing Up, you agree with our',
  dont_have_account: `Don't have an account?`,
  sign_up: `Sign Up`,
  username: `Username`,
  email: `Email`,
  full_name: `Full Name`,
  confirm_18: `I confirm that I am 18 years old and I have read the`,
  already_have_account: `Already have an account?`,
  share: `Share`,
  deposit: `Deposit`,
  bonus: `Bonus`,
  app: `App`,
  load_more: `Load More`,
  details: `Details`,
  helps: `HELPS`,
  kyc_policy: `KYC Policy`,
  privacy_policy: `Privacy Policy`,
  responsible_gaming: `Responsible Gaming`,
  terms_and_condition: `Terms And Condition`,
  faq: `FAQ`,
  about_us: `About Us`,
  amount: `Amount`,
  extra: `Extra`,
  deposit_bonus: `Deposit Bonus`,
  do_not_participate: `Do not participate in promotions`,
  payment_problem: `Payment Problem`,
  click_for_help: `Click for help`,
  deposit_notes: `Deposit Notes`,
  available_balance: `Available Balance`,
  withdraw: `Withdraw`,
  withdrawal_notes: `Withdrawal Notes`,
  withdrawal_note1: `Number of withdrawals in current`,
  withdrawal_note1_1: `vip`,
  withdrawal_note2: `Withdrawal fee:`,
  min: `Min:`,
  max: `Max:`,
  opening_times: `Opening times:`,
  withdrawal_note3: `1.Fastest withdrawal time within 10 minutes`,
  withdrawal_note4: `2.Make sure your withdrawal information is accurate, if the withdrawal information is wrong, it may cause your funds to lose.`,
  withdrawal_note5: `3.Please feel free to contact us if you have any questions.`,
  notifications: `Notifications`,
  invalid_x: `Invalid {{x}}`,
  captcha_not_match: `Captcha Does Not Match`,
  invalid_x_must_be_atleast_y_characters: `Invalid {{x}}, must be atleast {{y}} characters`,
  x_is_required: `{{x}} is required`,
  phone_number: 'Phone Number',
  phone_num_x_max: `Phone Number maximum {{x}} digits`,
  email_sent: `Email sent, please check your email.`,
  enter_valid_email: `Please enter a valid email.`,
  confirm: `Confirm`,
  password_atleast_6_characters: `Password must be atleast 6 characters.`,
  password_reset_success: `Password reset success!.`,
  new_password: `New Password`,
  bank: 'Bank',
  bank_transfer: `Bank Transfer`,
  bank_name: `Bank Name`,
  bank_branch: `Bank BSB`,
  pay_id: `Pay ID`,
  account_name: `Account Name`,
  account_number: `Account Number`,
  drop_or_select: `Drop or Select file`,
  drop_files_here: `Drop files here`,
  transfer_to: `Transfer To`,
  upload_receipt: `Upload Receipt`,
  select_bonus: `Select Bonus`,
  no_promotion: `No Promotion`,
  please_upload_receipt: `Please upload receipt`,
  bank_account: `Bank Account`,
  add_account: `Add Account`,
  account_holder_name: `Account Holder Name`,
  name_of_the_account_holder: `Name of the Account Holder`,
  fill_in_bank_account: `Fill in Bank Account`,
  withdraw_information: `Withdraw Information`,
  note: `Note`,
  amount_withdrawn: `Amount withdrawn`,
  amount_no_exceed_balance: `Withdraw Amount should not exceed the available balance`,
  minimum_withdraw_amount_is_x: `Minimum Withdraw amount is {{x}}`,
  transaction_history: `Transaction History`,
  date_from: `Date From`,
  date_to: `Date To`,
  account_name_must_be_4: `Account Name must be greater than 4 in length`,
  account_number_must_be_x: `Account number must be 10-14 in length`,
  minimum_length_is_x: `Minimum length is {{x}}`,
  maximum_length_is_x: `Maximum length is {{x}}`,
  payment_method: `Payment Method`,
  mode: `Mode`,
  no_bank_account: `You do not have any account to withdraw to, Add now`,
  please_select_bank: `Please select a Bank Account`,

  bet_history: `Bet History`,
  game_provider: `Game Provider`,
  bet_count: `Bet Count`,
  win_loss: `Win/Loss`,
  rebate: `Rebate`,
  turnover: `Turnover`,
  game_category: `Game Category`,

  you_have_x_unread_messages: `You have {{x}} unread messages`,
  deposit_amount_between_x_and_y: `Deposit amount must be between {{x}} and {{y}}`,
  minimum_amount_is_x_y: `Minimum amount is {{x}} {{y}}`,

  // all
  all: `All`,
  title: `Title`,
  date: `Date`,
  type: `Type`,
  status: `Status`,
  remark: `Remark`,
  category: `Category`,
  please_select_x: `Please select {{x}}`,
  save: `Save`,

  change_password: `Change Password`,
  current_password: `Current Password`,
  confirm_password: `Confirm Password`,
  submit: `Submit`,
  password_changed: `Password Changed`,
  x_must_be_y_characters: `{{x}} must be at least {{y}} characters`,
  reset_password: `Reset Password`,
  casino: `Casino`,
  slots: `Slots`,
  live_casino: `Live Casino`,
  fishing: `Fishing`,
  sports: `Sports`,
  cards: `Cards`,
  clear: `Clear`,
  search: `Search`,
  new: `New`,
  logout: `Logout`,
  choose_date: `Choose Date`,
  select_both_date: `Select both Date From and To`,
  from: `From`,
  to: `To`,
  apply: `Apply`,
  promotions: `Promotions`,
  learn_more: `LEARN MORE`,
  password_doesnt_match: `Password doesn't match`,
  cancel: `Cancel`,
  confirm_to_pay: `Confirm To Pay`,
  deposit_amount: `Deposit Amount`,
  loading_please_wait: `Loading, Please Wait`,
  account: `Account`,
  profile: `Profile`,
  bank_list: `Bank List`,
  in_maintenance: `In Maintenance`,
  birthday: `Birthday`,
  birthday_bonus: `Enter your birthday for a bonus! 🎉🎁`,
  referral_code: `Referral Code`,
  referral: `Referral`,
  my_referral: `My Referral`,
  my_claim: `My Claim`,
  claim_profit: `CLAIM PROFIT`,
  available_profit: `Available Profit`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `All Time Profit`,
  referral_note_on_x: `Earn profit every time your friends playing on {{x}}`,
  tier: `Tier`,
  commission: `Commission`,
  referred_member: `Referred Member`,
  profit_earned: `Profit Earned`,
  account_information: `Account Information`,
  invite_friends: `Invite Friends`,
  how_it_work: `How it work?`,
  invite_note: `Get exclusive referral bonuses when your friend signs up using your referral code!`,
  share_via: `Share via`,
  referral_link: `Referral Link`,
  total_profit: `Total Profit`,
  copied: `Copied`,
  nothing_to_claim: `Nothing to claim`,
  claim_history: `Claim History`,
  downline: `Downline`,
  last_x_digit_bank_ref_number: `Last {{x}} digit of Bank Ref number`,
  bank_ref_number_validation_x: `Bank Ref Number is required and must be {{x}} character length`,
  sort: 'Sort',
  login_directly: `Login directly with`,
  our_game_provides: `OUR GAME PROVIDERS`,
  refer_and_reward: `Refer & Reward`,
  linked_successfully_x: `Linked successfully with {{x}}! Please register your account for the first time`,
  more_x_of_y: `More {{x}}/{{y}}`,
  default: `Default`,
  promotion: `Promotion`,
  daily_mission: 'Daily Mission',
  agent: 'Agent',
  contact_us: 'Contact Us',
  download: 'Download',
  cash_claim: 'Cash Claim',
  demo: 'Demo',
  favorites: 'Favorites',
  popular: 'Popular',
  claim_bonus: `Claim Bonus`,
  insufficient_balance: `Insufficient Balance`,
  currently_displaying: 'Currently displaying {{x}} {{y}} games out of {{z}}',
  all_x: 'All {{x}}',
  no_results: 'No Results Found',
  search_games: 'Search Games',
  please_scan_using_your: `Please scan using your`,
  vip_level: `VIP Level`,
  bonus_direct: `BONUS DIRECT`,
  rebate_level: `Rebate Level`,
  vip_header: `All bets placed on Slot Machines, Board and Card Games, Fishing, Videos, Sports and Lottery Games at NextBetBD will be accumulated permanently. Valid bets = Permanent Income and Money Fortune!`,
  vip_example: `
  Example 1: Members who reach 1 Million in accumulated deposits, reach VIP Level 2, receive a bonus of R$210,and Rebate 3.50%, When they reach 6 Million in accumulated deposit, they reach VIP Level 3 and receive a bonus of R$800, with a Rebate 4%`,
  activity_rules: `Activity Rules:`,
  activity_rules_1: `1. It is necessary for all bonuses to have x3 Winover to request the Withdrawal`,
  activity_rules_2: `2. Please claim the Rebate Bonus within the deadline, if you happen to be late, it will be considered abandonment;`,
  activity_rules_3: `3. Members' participation in activities is automatically counted by the system, if there is any objection, the result of NextBetBD's investigation will prevail;`,
  activity_rules_4: `4. If you forget your member Account/Password, please contact "24-hour Online Customer Service" to help you recover your account information;`,
  activity_rules_5: `5. NextBetBD reserves the right to change, stop or cancel this promotion at any time.`,
  activity_rules_6: `6. Do not save free game , Buy free game`,
  activity_rules_7: `7. Can't combine with any bonus`,
  activity_rules_8: `8. Maximum Withdraw per Deposit is R$100,000`,
  event_instructions: 'Event Instructions',
  agent_p1: 'Share your unique link across apps and social media',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, among others, hurry up and invite everyone, members who register through your exclusive link will become your direct agents. But pay attention because the new members you invite must be genuine and valid.',
  agent_step1:
    'Get invitation friend the first deposit is successful, you can win a bonus of R$5. for example, if you invite 10 people for the first deposit, you can earn R$50. The more people you invite, the more bonuses you will receive. You can view your history and register on the promotion pageonuses and high commissions.',
  agent_step2:
    "If your guests bet R$1 or more, you can already enjoy high commission! You can earn up to 5% lifetime commission, and an invitation for life! The system will settle the previous day's commission at 7am the following day.",
  agent_step2_1: 'How to get: Login - Invitation promotion - Collect commission',
  agent_table1: 'Betting commission rates for slot, fishing and blockchain are as follows:',
  agent_table1_1: 'The customer topup 20 bet 200',
  person: 'person',
  agent_table2_title1: 'Member Betting',
  agent_table2_title2: 'Slot Game Only',
  agent_table2_example:
    'Example: You have 100 members, and the total real value of bets is estimated at 1 million reais per day. The commission you receive that day is: 1,000,000 x 1.20% 12,000 reais. A monthly income of 100,000 is super easy to achieve.',
  reminder: 'Reminder',
  agent_reminder:
    'A member account with the same IP, same device, name, bank card and other information can only participate once, and anyone who violates the rules will not benefit from this bonus. or the group will be confiscated and accounts will be permanently frozen',
  agent_step3_1:
    'The participation of members in activities is automatically counted by the system, if there is any objection, the result of the analysis carried out by NextBetBD will prevail',
  agent_step3_2:
    'If you forget your member account/password, please contact our 24-hour customer service team to help you recover your account information',
  agent_step3_3: 'NextBetBD reserves the right to change, interrupt or cancel the promotion at any time',
  agent_step3_4: 'Agent able to received the comission bonus is term of cash or credits in wallet',
  agent_step3_5: 'Do not save free game, Buy free game',
  agent_step3_6: "Can't combine with any bonus and deposit",
  agent_step3_7: 'Only available for Slot game',
  promo_concluded: 'Promo has concluded! Check back again tomorrow!',
  can_only_claim: 'Can only claim bonus between {{x}} to {{y}} Brazil Time',
  please_login: 'Please Login first to claim the bonus!',
  back_to_home: 'Back to Home',
  bonus_claimed: 'Bonus Already Claimed!',
  terms_n_conditions: `TERMS AND CONDITION`,
  claim_now: `CLAIM NOW`,
  the_activity_x_in: `The Activity {{x}} In`,
  starts: `Starts`,
  ends: `Ends`,
  list_of_winners: `LIST OF WINNERS`,
  days: `Days`,
  hours: `Hours`,
  minutes: `Minutes`,
  seconds: `Seconds`,
  total_amount_received: `Total Amount Received`,
  deposit_fee: `Deposit Fee`,
  get: 'Get',
  apply_now: `Apply Now`,
  number_exists: 'This phone number is already registered!',
  number_not_exists: 'This phone number is not registered!',
  payment_draft:
    'If you confirm you have already paid, please wait for a while as the payment might still be processing',
  my_downline: 'My Downline',
  downline_name: 'Downline Name',
  date_joined: 'Date Joined',
  deposit_amount_x_days: 'Deposit Amount ({{x}} Days)',
  welcome_to_bk: 'Welcome to NextBetBD',
  dont_have_an_account: `Don't have an account? `,
  fill_up_here: 'Fill Up Here',
  currency_and_language: 'Currency and Language',
  language: 'Language',
  deposit_channel: `Deposit Channel`,
  deposit_details: `Deposit Details`,
  bonus_amount: `Bonus Amount`,
  main_wallet: `Main Wallet`,
  withdraw_details: `Withdraw Details`,
  withdraw_amount: `Withdraw Amount`,
  password: 'Password',
  bank_details: `Bank Details`,
  add: `Add`,
  bank_holder_name: `Bank Holder Name`,
  bank_account_number: `Bank Account Number`,
  mandatory_field: `This is a mandatory field`,
  home: `Home`,
  password_not_match: 'Password do not match',
  payment_methods: 'Payment Methods',
  customer_support: 'Customer Support',
  chat_now: 'Chat Now',
  certifications: 'Certifications',
  community_website: 'Community Website',
  gaming_license: 'Gaming License',
  footer_title_1: 'Introduction To NextBetBD Online Casino',
  footer_paragraph_1:
    "Welcome to the NextBetBD of online gaming excitement – your trusted online casino in Bangladesh! Here at NextBetBD, we are dedicated to providing you with an unparalleled gaming experience that combines top-notch security, thrilling games, and exclusive rewards. Let's delve into what sets us apart and why we are your ultimate destination for online entertainment.",
  footer_title_2: 'Security and Trustworthiness',
  footer_paragraph_2:
    'At NextBetBD, your security is our top priority. We employ state-of-the-art encryption technology to safeguard your personal and financial information, ensuring that you can enjoy your gaming experience with peace of mind. Our platform is fully licensed and regulated, adhering to strict standards to guarantee fairness and transparency in every game.',
  footer_title_3: 'Exciting Game Selection',
  footer_paragraph_3:
    'Get ready to embark on an exhilarating journey through our extensive collection of games! From classic casino favorites like slots, roulette, and blackjack to cutting-edge live dealer experiences, we offer something for every type of player. Our games are developed by industry-leading software providers, delivering stunning graphics, seamless gameplay, and exciting bonus features.',
  footer_title_4: 'Exclusive Bonuses and Promotions',
  footer_paragraph_4:
    "As a valued member of NextBetBD, you'll be treated to a plethora of exclusive bonuses and promotions. From welcome bonuses that boost your initial deposits to ongoing promotions and loyalty rewards, we believe in rewarding our players generously. Keep an eye on our promotions page for the latest offers and maximize your gaming experience with extra rewards.",
  footer_title_5: 'User-Friendly Interface',
  footer_paragraph_5:
    "Navigating our platform is a breeze, thanks to our user-friendly interface designed with your convenience in mind. Whether you're accessing our casino on desktop, mobile, or tablet devices, you'll enjoy seamless gameplay and hassle-free navigation. Our intuitive interface ensures that you can focus on what matters most – having fun and winning big!",
  footer_title_6: '24/7 Customer Support',
  footer_paragraph_6:
    "Need assistance? Our dedicated customer support team is available 24/7 to address any queries or concerns you may have. Whether you have questions about account registration, payment methods, or game rules, our friendly support agents are here to help. Reach out to us via live chat, email, or phone, and we'll ensure that your gaming experience remains smooth and enjoyable.",
  footer_title_7: 'Responsible Gaming',
  footer_paragraph_7:
    'We are committed to promoting responsible gaming practices and ensuring the well-being of our players. Our platform includes various tools and resources to help you manage your gaming activity responsibly. From setting deposit limits to self-exclusion options, we empower you to stay in control of your gaming habits and enjoy our platform responsibly.',
  footer_title_8: 'Fully Compliance With Licensed Online Casino Bangladesh',
  footer_paragraph_8:
    'Explore the excitement of online gaming with our NextBetBD premier casino platform in Bangladesh. Discover a wide array of thrilling games, from classic slots to immersive live dealer experiences. Join now for exclusive bonuses and unparalleled entertainment. Play responsibly. Must be 18+. T&C apply.',
  footer_title_9: 'NextBetBD Offer The Best Online Casino Experience',
  footer_paragraph_9:
    "Experience the ultimate in online gaming entertainment with NextBetBD, your trusted online casino in Bangladesh. With a focus on security, excitement, and rewards, we're here to elevate your gaming experience to new heights. Join us today and discover why we're the preferred choice for discerning players across Bangladesh. Let the games begin!",
  footer_copyright: 'Copyright © 2024 NextBetBD.All rights are reserved.',
  currency: 'Currency',
  fill_up_password_here: 'Fill up password here',
  betting_records: 'Betting Records',
  turn_over: 'Turn Over',
  transfer_records: 'Transfer Records',
  transaction_records: 'Transaction Records',
  funds: 'Funds',
  history: 'History',
  claim_voucher: 'Claim Voucher',
  check_in_lucky: 'Check-In / Lucky Wheel',
  personal_info: 'Personal Info',
  inbox_messages: 'Inbox Messages',
  referral_bonus: 'Referral Bonus',
  support: '24/7 Support',
  whatsapp: 'WhatsApp',
  facebook: 'Facebook',
  downloads: 'Downloads',
  download_android: 'Download Android',
  overview: 'Overview',
  referral_program: 'Referral Program',
  summary: 'Summary',
  referral_report: 'Referral Report',
  redeem_history: 'Redeem History',
  commission_to_redeem: 'Commission To Redeem',
  today_commission: 'Today Commission',
  this_month_commission: 'This Month Commission',
  referral_tier: 'Referral Tier',
  total: 'Total',
  share_now: 'Share Now',
  complete: 'Complete',
  back: 'Back',
  c_bet_amount: 'BET AMOUNT',
  c_rebate: 'REBATE',
  c_turnover: 'TURNOVER',
  c_amount: 'AMOUNT',
  c_bonus: 'BONUS',
  c_remarks: 'REMARKS',
  level: 'Level',
  no_promotions: 'No Promotions',
  dont_show_again: "Don't show again",
  next: 'Next',
  close: 'Close',
  crash_game: `Crash Game`,
  maximum_withdraw_amount_is_x: `Maximum Withdraw Amount is {{x}}`,
  no_balance: `No Balance`,
  no: `No`,
  optional: `Optional`,
  network_error: `Network Error`,
  game_providers: `Game Providers`,
  sub_filters: `Sub Filters`,
  balance: 'Balance',
  requirement: 'Requirement',
  deposit_from_bank: 'Deposit From Bank',
  footer_banner_desc: `Get the best odds from a wide variety of sports and virtuals of unlimited fun in our 24/7 live betting`,
  official_partner_of: `Official Partner of`,
  site_description: `Welcome to nextbetbd.com, one of Asia's leading online betting and gaming sites.`,
  site_description_1: `NextBetBD offers a wide variety of sporting markets and worldwide sporting events, boasting over 15,000 live sports events to bet on. Combined with a huge variety of live dealers, slots, arcade games and our generous promotions across all of our products, we promise you will enjoy the highest class of online gaming entertainment of the world.`,
  site_description_2: `At NextBetBD, your security is our top priority. We employ state-of-the-art encryption technology to safeguard your personal and financial information, ensuring that you can enjoy your gaming experience with peace of mind. Our platform is fully licensed and regulated, adhering to strict standards to guarantee fairness and transparency in every game.`,
  site_description_3: `As a valued member of NextBetBD, you'll be treated to a plethora of exclusive bonuses and promotions. From welcome bonuses that boost your initial deposits to ongoing promotions and loyalty rewards, we believe in rewarding our players generously. Keep an eye on our promotions page for the latest offers and maximize your gaming experience with extra rewards.`,
  site_description_4: `Navigating our platform is a breeze, thanks to our user-friendly interface designed with your convenience in mind. Whether you're accessing our casino on desktop, mobile, or tablet devices, you'll enjoy seamless gameplay and hassle-free navigation. Our intuitive interface ensures that you can focus on what matters most - having fun and winning big!`,
  site_description_5: `Need assistance? Our dedicated customer support team is available 24/7 to address any queries or concerns you may have. Whether you have questions about account registration, payment methods, or game rules, our friendly support agents are here to help. Reach out to us via live chat, email, or phone, and we'll ensure that your gaming experience remains smooth and enjoyable.`,
  site_description_6: `Experience the ultimate in online gaming entertainment with NextBetBD, your trusted online casino in Bangladesh. With a focus on security, excitement, and rewards, we're here to elevate your gaming experience to new heights. Join us today and discover why we're the preferred choice for discerning players across Bangladesh. Let the games begin!`,
  payment: `Payment`,
  vip_faq: `VIP Membership FAQ`,
  vip_faq_1: `How to become a Nextbetbd VIP Member?`,
  vip_faq_2: `No hassle, no additional cost!`,
  vip_faq_3: `Simply register at Nextbetbd as member, deposit and start placing bets.`,
  vip_faq_4: `Upgrades are automatic upon meeting deposit requirements for each tier, allowing you to enjoy VIP benefits instantly`,
  vip_faq_5: `How do I get upgraded?`,
  vip_faq_6: `Meet the deposit requirements within the designated timeframe.`,
  vip_faq_7: `Automatic upgrades to the eligible VIP tier`,
  vip_faq_8: `No skipping of the VIP tier; tiers are progressed one at a time.`,
  vip_faq_9: `Refer to the VIP tier chart for specific deposit requirements.`,
  vip_faq_10: `How do I get upgrade bonus?`,
  vip_faq_11: `Upgrade bonuses are awarded instantly upon upgrade `,
  vip_faq_12: `Each tier upgrade bonus can be claimed once.`,
  vip_faq_13: `Multiple level upgrades are possible by meeting each deposit requirement within 30 days.`,
  vip_faq_14: `How is the deposit amount determined?`,
  vip_faq_15: `Based on accumulated deposits.`,
  vip_faq_16: `Upgrades must be followed by meeting the next level's deposit requirement within 30 days from the previous upgrade.`,
  vip_faq_17: `Timelines are unique to each member.`,
  vip_faq_18: `Why is my VIP Level downgraded?`,
  vip_faq_19: `Failure to meet the retention requirement results in a downgrade.`,
  vip_faq_20: `To retain your VIP level and benefits, achieve the monthly retention amount.`,
  vip_faq_21: `Example: To stay as VIP Silver, maintain a monthly accumulated deposit of ৳62,500`,
  vip_tnc: `VIP Membership Terms & Conditions`,
  vip_tnc_1: `Nextbetbd reserves the right to alter, cancel, terminate or suspend the redemption or any part thereof or any part of the applicable terms and conditions from time to time, with or without any prior notice.`,
  vip_tnc_2: `Members participate in the VIP program must accept and comply with the above rules and terms as well as all relevant rules and terms of the website implement by Nextbetbd.`,
  vip_tnc_3: `VIP MEMBERSHIP Terms & Conditions Applies.`,
  vip_benefits: `VIP REQUIREMENTS & BENEFITS`,
  vip_upgrade: `VIP Upgrade`,
  monthly_deposit_requirement: `Monthly Deposit Requirement`,
  vip: `VIP Benefits`,
  upgrade_bonus: `Upgrade bonus`,
  birthday_bns: `Birthday Bonus`,
  merch_and_gifts: `VIP Merchandise & Special Gifts`,
  vip_cs: `Express VIP Customer Support`,
  vip_priority: `Deposit & Withdrawal Priority`,
  vip_events: `VIP Events & Experiences`,
  vip_exclusive_access: `Exclusive Access to New Features`,
  dedicated_manager: `Dedicated VIP Manager`,
  invited_only: `Invited Only`,
  become_vip: `BE Nextbetbd VIP`,
  become_vip_1: `Become our VIP Member for exclusive offers, opulent gifts, generous bonuses, and naturally, the highest
              level of priority services.`,
  become_vip_2: `Elevate your status and experience unparalleled service with us!`,
  become_vip_3: `How to join Nextbetbd VIP Membership?`,
  become_vip_4: `No hassle, No wait - Instant Upgrade!`,
  become_vip_5: `Automatic Upgrade: Meet the deposit requirement to become a VIP.`,
  become_vip_6: `*Check the table below for more details.`,
  become_vip_7: `Enjoy VIP perks instantly!`,
  vip_retention_requirement: `VIP Retention Requirement`,
  monthly_accumulated_deposit: `Monthly Accumulated Deposit`,
  membership_renewal: `Membership Renewal`,
};

export default en;
