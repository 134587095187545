import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Box, Button, Grid, Stack, Toolbar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import LoginSignupDialog from './visitor/LoginSignupDialog';
import ForgotPasswordDialog from './forgotpassword/ForgotPasswordDialog';
import ResetPasswordDialog from './forgotpassword/ResetPasswordDialog';
import { useDispatch, useSelector } from '../../../redux/store';
import { openLoginSignup } from '../../../redux/slices/authPages';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import Image from '../../../components/Image';
import NotificationPopover from './NotificationPopover';
import AccountPopover from './AccountPopover';
import useLocales from '../../../hooks/useLocales';
import { fNumber, fNumberDecimal } from '../../../utils/formatNumber';
import palette from '../../../theme/palette';
import { ThemeButton } from '../../GlobalStyles';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  // boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  background: palette.header.bgColor,
  // borderTop: `1px solid ${palette.header.border}`,
  // borderBottom: `1px solid ${palette.header.border}`,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
  openAccountDrawer: PropTypes.bool,
  setOpenAccountDrawer: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
  openAccountDrawer,
  setOpenAccountDrawer,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { isAuthenticated, logout } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { memberBalance } = useSelector((x) => x.lookup);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useNavigate();

  const [showWallet, setShowWallet] = useState(false);

  useEffect(() => {
    // if (localStorage.getItem('referralCode') && !localStorage.getItem('accessToken')) {
    //   setTimeout(() => {
    //     dispatch(openLoginSignup({ open: true, isLogin: false }));
    //   }, 1000);
    // }
    // Check if got resetpassword token
    if (localStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      // getProfile();
    } else {
      console.log('loggedoff');
    }
  }, [localStorage.getItem('accessToken')]);

  const balanceDisplay = useMemo(() => {
    const rawBalance = memberBalance?.displayBalance !== '0' ? memberBalance?.displayBalance : '0.00';
    if (showWallet) {
      return rawBalance;
    }

    return rawBalance.toString().replace(/\d/g, '*');
  }, [showWallet, memberBalance]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          pl: { lg: 1 },
          pr: { lg: 2 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        <Box sx={{ flex: { xs: 1, lg: 0 } }} onClick={onOpenSidebar}>
          <Iconify icon={'ic:round-menu'} sx={{ color: '#fff', width: '24px', height: '24px', cursor: 'pointer' }} />
        </Box>

        {!isDesktop && (
          <Stack direction="row" alignItems={'center'} justifyContent="center" sx={{ flex: { xs: 1, lg: 0 } }}>
            <Box sx={{ width: { sm: '5rem', md: '7rem' } }} onClick={() => navigate('/home')}>
              <Image
                src={require('../../../assets/brand-logo.png')}
                alt=""
                sx={{ cursor: 'pointer', width: '140px', height: '30px' }}
              />
            </Box>
          </Stack>
        )}

        <Stack direction={'row'} spacing={3} flex={1} justifyContent={'end'} alignItems={'center'}>
          {isDesktop && !localStorage.getItem('accessToken') && (
            <>
              <LoadingButton
                variant="outlined"
                sx={{
                  padding: '5px 20px',
                  border: '1px solid #fff',
                  color: '#fff',
                  borderRadius: '4px',
                  '&:hover': {
                    borderColor: '#fff',
                  },
                }}
                onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
              >
                {translate('login')}
              </LoadingButton>
              <ThemeButton
                sx={{
                  padding: '5px 20px',
                }}
                onClick={() => navigate('/auth/register')}
              >
                {translate('sign_up')}
              </ThemeButton>
            </>
          )}
          {isDesktop && localStorage.getItem('accessToken') && (
            <>
              <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography>{translate('main_wallet')}</Typography>
                <Typography>
                  {memberBalance?.currencyCode} {balanceDisplay}
                  {/* {
                    showWallet && memberBalance?.displayBalance !== undefined
                    ? memberBalance.displayBalance !== '0' ? memberBalance.displayBalance : '0.00'
                    : memberBalance?.displayBalance !== undefined
                        ? '0.00'
                        : memberBalance?.displayBalance?.toString().replace(/\d/g, '*')
                  } */}
                </Typography>
                <Iconify
                  icon={showWallet ? 'eva:eye-off-fill' : 'eva:eye-fill'}
                  onClick={() => setShowWallet(!showWallet)}
                  sx={{
                    color: '#F0C335',
                    fontSize: '20px',
                    cursor: 'pointer',
                  }}
                />
              </Stack>
              <ThemeButton
                size="small"
                sx={{
                  padding: '1px',
                }}
                onClick={() => navigate('/user/wallet')}
              >
                {translate('deposit')}
              </ThemeButton>
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => setOpenAccountDrawer(true)}
              >
                {translate('profile')}
              </Typography>
              <Typography
                sx={{
                  cursor: 'pointer',
                }}
                onClick={handleLogout}
              >
                {translate('logout')}
              </Typography>
              <NotificationPopover />
            </>
          )}
        </Stack>

        {isOpenLoginSignupDialog.open && (
          <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
        )}

        {openForgotPasswordDialog && (
          <ForgotPasswordDialog
            openForgotPasswordDialog={openForgotPasswordDialog}
            setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
          />
        )}

        {openResetPasswordDialog && (
          <ResetPasswordDialog
            openResetPasswordDialog={openResetPasswordDialog}
            setOpenResetPasswordDialog={setOpenResetPasswordDialog}
            setOpenLoginSignup={(params) => dispatch(openLoginSignup(params))}
          />
        )}
      </Toolbar>
    </RootStyle>
  );
}
